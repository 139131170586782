import { apiSignin } from '../../api/signin';
import { routes } from '../../router';
import { userLogin } from '../../services/userService';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
  data: () => {
    return {
      form: {
        username: '',
        email: '',
        password: ''
      },
      showSigninError: false,
      showValidationErrors: false,
      pending: false,
      routes: routes
    };
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },

  computed: {},

  methods: {
    async onSubmitSignIn() {
      if (this.$v.$invalid) {
        this.showValidationErrors = true;
      } else {
        this.showValidationErrors = false;
        try {
          this.pending = true;
          const apiResult = await apiSignin(this.form);
          await userLogin(apiResult.data);
          this.pending = false;
          this.$router.push(routes.albums);
        } catch (error) {
          console.log('onSubmitSignIn error: ', error.response.data);
          this.showSigninError = true;
          this.pending = false;
        }
      }

    }
  }
};
