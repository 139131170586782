import axios from 'axios';
import { CONST } from './apiConfig';

const apiEndpoint1 = 'subscribe-anonymous';
const apiEndpoint2 = 'subscribe';
const apiEndpoint3 = 'unsubscribe';

async function apiSubscribeAnonymous(credentials) {
  const apiUrl = [CONST.API_BASE_URL, apiEndpoint1].join('/');

  const payload = {
    ownerName: credentials.ownerName,
    accessKey: credentials.accessKey
  };

  let apiResult = await axios.post(apiUrl, payload);
  return apiResult.data;
}

async function apiSubscribe(credentials) {
  const apiUrl = [CONST.API_BASE_URL, apiEndpoint2].join('/');

  const payload = {
    ownerName: credentials.ownerName,
    accessKey: credentials.accessKey
  };

  let apiResult = await axios.post(apiUrl, payload);
  return apiResult.data;
}

async function apiUnsubscribe(galleryId){
  const apiUrl = [CONST.API_BASE_URL, apiEndpoint3].join('/');

  const payload = {
    galleryId: galleryId
  };

  try {
    await axios.post(apiUrl, payload);
    return galleryId;
  } catch (err) {
    console.log('Error apiUnsubscribe: ', err);
  }
}

export {
  apiSubscribeAnonymous,
  apiSubscribe,
  apiUnsubscribe
};
