import store from '../store';
import { getRemoteGalleries } from '../api/galleries';
import { importRemoteImages } from './remoteImageImportService';
import { deleteLocalImages, removeDeletedImagesFromServer, removeImageFromStore } from './imageDeletionService';
import { removeDeletedGalleriesFromServer, deleteLocalGallery } from './galleryDeletionService';
import differenceBy from 'lodash/differenceBy';
import { addNewGalleriesOnServer, addNewImagesOnServer } from './uploadService';
import { checkForAppUpdates } from './appUdatedService';
import { unsubscribeUserFromGalleries } from './subscriptionService';
import { importGalleries } from './galleryImportService';
import { CONSTANTS } from '../shared/constants';

const SYNC_INTERVAL = 5 * 60000; // 5 minutes

let nextSyncTimer;
let syncInProgress = false;

/**
 * Conveniently update several gallery properties in one go.
 * Properties could be the title, numberOfSubscribers etc.
 * @param remoteGallery
 * @returns {*}
 */
function syncProperties(remoteGallery) {
  return store.dispatch({
    type: 'updateGalleryProperties',
    remoteGallery: remoteGallery
  });
}

function fixImageId(clientImageId, serverImageId) {
  return store.dispatch({
    type: 'fixImageAfterBrokenUpload',
    clientImageId: clientImageId,
    serverImageId: serverImageId
  });
}

function fixDuplicates(duplicates) {
  const promises = [];

  duplicates.forEach(image => {
    promises.push(fixImageId(image.clientImageId, image.imageId));
  });

  return Promise.all(promises);
}

/**
 * Broken image uploads are happening when an image was added on the server but the server success response
 * did not reach the client. In such a case on the next synchronization round the added image would be treated
 * as new to the client and result in duplicates.
 * @param newImages
 * @returns {Promise<[]>}
 */
async function checkForBrokenUploads(newImages) {
  const images = [];
  const duplicates = [];

  newImages.forEach(image => {
    const duplicate = store.getters.getImageById(image.clientImageId);
    if (duplicate) {
      duplicates.push(image);
    } else {
      images.push(image);
    }
  });

  if (duplicates) {
    await fixDuplicates(duplicates);
  }

  return images;
}

/**
 * Diff the gallery images model from the server with the model of the client and handle the result.
 * @param remoteGallery
 * @param localGallery
 * @returns {Promise<void>}
 */
async function syncImages(remoteGallery, localGallery) {
  const localImages = store.getters.uploadedImagesOfGallery(localGallery.galleryId);
  let newImages = differenceBy(remoteGallery.images, localImages, 'imageId');
  const deletedImages = differenceBy(localImages, remoteGallery.images, 'imageId');

  newImages = await checkForBrokenUploads(newImages);

  if (newImages.length) {
    await importRemoteImages(newImages, remoteGallery.galleryId, true);
  }

  if (deletedImages.length) {
    try {
      await deleteLocalImages(deletedImages);
    } catch (err) {
      console.log('error: delete images');
    }
  }
}

function markGalleryAsUpdated(galleryId, updatedAt) {
  return store.dispatch({
    type: 'markGalleryAsUpdated',
    galleryId: galleryId,
    updatedAt: updatedAt
  });
}

async function syncLocalUpdates() {
  await addNewGalleriesOnServer();
  await removeDeletedGalleriesFromServer();
  await unsubscribeUserFromGalleries();
  await addNewImagesOnServer();
  await removeDeletedImagesFromServer();
}

function scheduleNextSync() {
  clearTimeout(nextSyncTimer);
  nextSyncTimer = setTimeout(() => {
    sync();
  }, SYNC_INTERVAL);
}

function removeLoadingGalleries() {
  return Promise.all(store.getters.loadingGalleries.map(gallery => {
    return deleteLocalGallery(gallery);
  }));
}

function removeLoadingImages() {
  return Promise.all(store.getters.loadingImages.map(image => {
    return removeImageFromStore(image);
  }));
}

async function updateLocalGallery(remoteGallery) {
  let localGallery = store.getters.getGalleryById(remoteGallery.galleryId);

  if (localGallery.updatedAt !== remoteGallery.updatedAt || localGallery.status === CONSTANTS.STATUS.LOADING) {
    await syncImages(remoteGallery, localGallery);
    await syncProperties(remoteGallery);
    await markGalleryAsUpdated(localGallery.galleryId, remoteGallery.updatedAt);
  }
}

async function syncRemoteUpdates() {
  const apiResult = await getRemoteGalleries();
  const remoteGalleries = apiResult.galleries;
  const localGalleries = store.getters.uploadedGalleries;

  const newGalleries = differenceBy(remoteGalleries, localGalleries, 'galleryId');
  const deletedGalleries = differenceBy(localGalleries, remoteGalleries, 'galleryId');
  const existingGalleries = differenceBy(remoteGalleries, newGalleries, 'galleryId');

  if (newGalleries.length) {
    await importGalleries(newGalleries);
  }

  if (deletedGalleries.length) {
    await Promise.all(deletedGalleries.map(gallery => deleteLocalGallery(gallery)));
  }

  if (existingGalleries.length) {
    await Promise.all(existingGalleries.map(gallery => updateLocalGallery(gallery)));
  }
}

async function cleanupUnfinishedTasks () {
  await removeLoadingImages();
  // todo: Find out what the better user experience is here! Leave unfinished galleries or remove them.
  // await removeLoadingGalleries();
}

async function sync() {
  checkForAppUpdates();
  scheduleNextSync();
  if (!syncInProgress) {
    syncInProgress = true;
    if (store.getters.hasActiveSession) {
      console.log('*** start sync ***');
      try {
        await cleanupUnfinishedTasks();
        await syncRemoteUpdates(); // remote updates need to be synced first!
        await syncLocalUpdates();
      } catch (err) {
        console.log('sync failed!', err);
      }
      console.log('*** sync done! ***');
    }
    syncInProgress = false;
  } else {
    console.log('SYNC ALREADY IN PROGRESS!');
  }
}

export {
  sync
};
