import { routes } from '../../router';

export default {
    data() {
        return {
            routes
        }
    },

    methods: {
        onSlideshowItemClick() {
            const lastRouteSegment = this.$router.currentRoute.path.split('/').pop();

            if (lastRouteSegment !== routes.settingsSlideshow) {
                this.$router.push(this.$router.currentRoute.path + '/' + routes.settingsSlideshow);
            }
        }
    }
};
