import { mapGetters } from 'vuex';

export default {
  props: ['galleryId'],

  computed: {
    ...mapGetters([
      'imagesOfGallery',
      'getGalleryById'
    ]),
    numberOfImages() {
      return this.imagesOfGallery(this.galleryId).length;
    },
    numberOfNewImages() {
      return this.imagesOfGallery(this.galleryId)
        .filter( image => image.isNew)
        .length
    },
    gallery() {
      return this.getGalleryById(this.galleryId);
    }
  }
}
