function getDefaultState() {
    return {
        slideshow: {
            activeType: 'ken-burns',
            types: [
                {
                    name: 'ken-burns',
                    interval: 7000
                },
                {
                    name: 'cross-fade',
                    interval: 7000
                },
                {
                    name: 'slide',
                    interval: 7000
                },
                {
                    name: 'daily',
                    time: 12
                },
            ]
        }
    };
}

export default {

    state: getDefaultState(),

    getters: {
        activeSlideshow(state){
          return state.slideshow.types.find( type => type.name === state.slideshow.activeType);
        },
        slideshowTransitionName(state) {
            return state.slideshow.activeType;
        },
        slideshowIntervalDuration(state) {
            return state.slideshow.types.find( type => type.name === state.slideshow.activeType).interval;
        }
    },

    mutations: {
        resetSettings(state) {
            Object.assign(state, getDefaultState());
        },
        setActiveSlideshow(state, name) {
            state.slideshow.activeType = name;
        },
        setSlideshowTransitionName(state, transitionName) {
            state.slideshow.transitionName = transitionName;
        },
        setSlideshowIntervalDuration(state, interval) {
            const activeSlideshow = state.slideshow.types.find( type => type.name === state.slideshow.activeType);
            activeSlideshow.interval = interval;
        }
    },

    actions: {
        resetSettings(context) {
            context.commit('resetSettings');
        },
        setActiveSlideshow(context, payload) {
            context.commit('setActiveSlideshow', payload.slideshowName);
        },
        setSlideshowTransitionName(context, payload) {
            context.commit('setSlideshowTransitionName', payload.transitionName);
        },
        setSlideshowIntervalDuration(context, payload) {
            context.commit('setSlideshowIntervalDuration', payload.intervalDuration);
        }
    }
};
