import { showModal } from './modalService';

function showDeleteGalleryNotification() {
  return showModal({
    title: 'Album wirklich löschen?',
    text: [
      'Das Album wird auch bei allen Personen gelöscht, mit denen Sie es teilen.'
    ],
    buttonLabel: 'Löschen'
  });
}

function showStopSharingGalleryNotification() {
  return showModal({
    title: 'Teilen wirklich beenden?',
    text: [
      'Das Album wird dann bei allen Personen gelöscht, mit denen Sie es teilen.'
    ],
    buttonLabel: 'Teilen beenden'
  });
}

function showImageLimitReachedNotification() {
  return showModal({
    title: 'Maximale Anzahl an Fotos überschritten!',
    text: [
      'Mit Ihrer Auswahl haben Sie die maximale Anzahl von 30 Fotos je Album überschritten.',
      'Sie können ältere Fotos entfernen, Ihre Auswahl verringern oder für die App etwas bezahlen.'
    ],
    buttonLabel: 'Ok'
  });
}

function showGalleriesLimitReachedNotification() {
  return showModal({
    title: 'Maximale Anzahl an Alben erreicht!',
    text: [
      'Wenn Sie ein neues Album anlegen möchten, müssen Sie vorher ein anderes löschen oder für die App bezahlen.',
    ],
    buttonLabel: 'Ok'
  });
}

function showUnsubscribeNotification() {
  return showModal({
    title: 'Album wirklich entfernen?',
    text: [
      'Wollen Sie die lokale Kopie des Albums wirklich entfernen?'
    ],
    buttonLabel: 'Entfernen'
  });
}

export {
  showDeleteGalleryNotification,
  showStopSharingGalleryNotification,
  showImageLimitReachedNotification,
  showGalleriesLimitReachedNotification,
  showUnsubscribeNotification
}
