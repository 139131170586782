export default {
    state: {
        currentRoute: '',
        previousRoute: ''
    },
    getters: {
        currentRoute(state) {
            return state.currentRoute;
        },
        previousRoute(state) {
            return state.previousRoute;
        }
    },
    mutations: {
        setCurrentRoute(state, currentRoute) {
            state.previousRoute = state.currentRoute;
            state.currentRoute = currentRoute;
        }
    },
    actions: {
        setCurrentRoute(context, payload) {
            context.commit('setCurrentRoute', payload.currentRoute);
        }
    }
};