let update = false;
let waitingServiceWorker;

function enableUpdate(serviceWorkerRegistration) {
  update = true;
  waitingServiceWorker = serviceWorkerRegistration.waiting;
}

function hasUpdate () {
  return update;
}

function triggerUpdate() {
  if (waitingServiceWorker) {
    waitingServiceWorker.postMessage(
      {
        id: 'UPDATE-CLIENT'
      }
    );
  }
}

function checkForAppUpdates() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.update()
      }
    })
  }
}

export {
  enableUpdate,
  hasUpdate,
  triggerUpdate,
  checkForAppUpdates
}
