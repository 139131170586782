import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

function enableWakeLock () {
  noSleep.enable();
}

function disableWakeLock () {
  noSleep.disable();
}

export {
  enableWakeLock,
  disableWakeLock
}
