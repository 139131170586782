import store from '../store';
import { from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { apiSubscribe, apiSubscribeAnonymous, apiUnsubscribe } from '../api/subscribe';
import { importGallery } from './galleryImportService';
import { anonymousLogin } from './userService';

async function removeGalleryFromStore(galleryId) {
  await store.dispatch({
    type: 'removeGallery',
    galleryId: galleryId
  });
}

function unsubscribeUserOnServer(gallery) {
  // API call
  return apiUnsubscribe(gallery.galleryId)
}

function unsubscribe(galleries) {
  return from(galleries)
    .pipe(
      flatMap(unsubscribeUserOnServer),
      flatMap(removeGalleryFromStore)
    )
    .toPromise();
}

async function subscribe(accessKey) {
  let apiResult = await apiSubscribe(accessKey);
  if (apiResult.gallery) {
    // Import received gallery
    await importGallery(apiResult.gallery);
  } else if (apiResult.galleryId) {
    // If gallery is owned or already subscribed to then just open the edit view of the gallery.
    await store.dispatch({
      type: 'setActiveGalleryId',
      galleryId: apiResult.galleryId
    });
  } else {
    // subscription failed
    throw new Error('subscription failed!');
  }
}

async function subscribeAnonymous(accessKey) {
  let apiResult = await apiSubscribeAnonymous(accessKey);
  if (apiResult.gallery) {
    anonymousLogin(apiResult.token);
    await importGallery(apiResult.gallery);
    await store.dispatch({
      type: 'setActiveGalleryId',
      galleryId: apiResult.gallery.galleryId
    });
  } else {
    // subscription failed
    throw new Error('anonymous subscription failed!');
  }

}

async function unsubscribeUserFromGalleries() {
  const unsubscribedGalleries = store.getters.unsubscribedGalleries;
  if (unsubscribedGalleries.length) {
    await unsubscribe(unsubscribedGalleries);
  }
}

export {
  subscribe,
  subscribeAnonymous,
  unsubscribeUserFromGalleries
}
