import { mapGetters } from 'vuex';
import { CONSTANTS } from '../../shared/constants';
import { routes } from '../../router';
import { required } from 'vuelidate/lib/validators'

export default {
  data: function () {
    return {
      galleryTitle: '',
      showValidationErrors: false
    };
  },

  validations: {
    galleryTitle: {
      required
    }
  },

  computed: {
    ...mapGetters([
      'galleries',
      'userId',
      'username',
      'isAuthenticated'
    ])
  },
  methods: {
    async onSubmitCreateGallery() {
      if (this.$v.$invalid) {
        this.showValidationErrors = true;
      } else {
        this.showValidationErrors = false;
        await this.$store.dispatch({
          type: 'createGallery',
          title: this.galleryTitle,
          isOwned: true,
          ownerName: this.username,
          uploaded: false,
          syncAction: CONSTANTS.SYNC_ACTION.ADD
        });
        this.$router.push(routes.albumEdit);
      }
    }
  }
};
