import Vue from 'vue';
import * as Vuex from 'vuex';
import galleriesModule from './modules/galleries';
import imagesModule from './modules/images';
import userModule from './modules/user';
import settingsModule from './modules/settings';
import routesModule from './modules/routes';
import offlineStoragePlugin from './plugins/offlineStoragePlugin';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        galleries: galleriesModule,
        images: imagesModule,
        user: userModule,
        settings: settingsModule,
        routes: routesModule
    },
    plugins: [
        offlineStoragePlugin
    ]
});
