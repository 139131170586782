import { createUUID } from '../../services/uuidService';
import { GALLERY_SETTINGS } from '../../services/settingsService';
import { updateGallerySettings, revokeGallerySubscriptions } from '../../api/galleries';
import { CONSTANTS } from '../../shared/constants';

function getDefaultState() {
  return {
    activeGallery: '',
    galleries: []
  };
}

function setGalleryDefaultProperties(payload) {
  return {
    galleryId: payload.galleryId || createUUID(),
    title: payload.title,
    updatedAt: 0,
    isOwned: payload.isOwned,
    ownerName: payload.ownerName,
    settings: GALLERY_SETTINGS,
    writePermissionForSubscribers: payload.writePermissionForSubscribers || false,
    numberOfSubscribers: payload.numberOfSubscribers || 0,
    purchase: {
      purchaseDate: null,
      expirationDate: null
    },
    status: CONSTANTS.STATUS.LOADING,
    uploaded: payload.uploaded || false,
    syncAction: payload.syncAction
  };
}


export default {
  state: getDefaultState(),
  getters: {
    galleries(state) {
      return state.galleries.filter(gallery => gallery.status !== CONSTANTS.STATUS.DELETED);
    },
    ownedGalleries(state) {
      return state.galleries.filter(gallery => gallery.isOwned);
    },
    subscribedGalleries(state) {
      return state.galleries.filter((gallery) => {
        return !gallery.isOwned;
      });
    },
    uploadedGalleries(state) {
      return state.galleries
        .filter(gallery => gallery.uploaded);
        // .filter(gallery => gallery.status === CONSTANTS.STATUS.READY);
    },
    notUploadedGalleries(state) {
      return state.galleries
        .filter(gallery => gallery.syncAction === CONSTANTS.SYNC_ACTION.ADD);
    },
    activeGalleryId(state) {
      return state.activeGallery;
    },
    activeGallery(state) {
      return state.galleries.find(gallery => gallery.galleryId === state.activeGallery);
    },
    getGalleryById(state) {
      return (id) => state.galleries.find(gallery => {
        return gallery.galleryId === id;
      });
    },
    deletedGalleries(state) {
      return state.galleries.filter(gallery => gallery.syncAction === CONSTANTS.SYNC_ACTION.DELETE);
    },
    unsubscribedGalleries(state) {
      return state.galleries.filter(gallery => gallery.syncAction === CONSTANTS.SYNC_ACTION.UNSUBSCRIBE);
    },
    loadingGalleries(state) {
      return state.galleries.filter(gallery => gallery.status === CONSTANTS.STATUS.LOADING);
    }
  },
  mutations: {
    resetGalleries(state) {
      Object.assign(state, getDefaultState());
    },
    setState(state, inputState) {
      state = inputState;
    },
    addGallery(state, gallery) {
      state.galleries.push(gallery);
      state.activeGallery = gallery.galleryId;
    },
    setActiveGalleryId(state, galleryId) {
      state.activeGallery = galleryId;
    },
    updateGalleryAfterUpload(state, payload) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.oldGalleryId);

      gallery.galleryId = state.activeGallery = payload.newGalleryId;
      gallery.updatedAt = payload.updatedAt;
      gallery.uploaded = true;
      gallery.syncAction = CONSTANTS.SYNC_ACTION.NONE;
    },
    finishGalleryImport(state, payload) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.galleryId);

      gallery.status = CONSTANTS.STATUS.READY;
      gallery.updatedAt = payload.updatedAt;
    },
    updateGallerySettings(state, apiResult) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === apiResult.galleryId);
      gallery.updatedAt = apiResult.updatedAt;
      gallery[apiResult.settingsKey] = apiResult.settingsValue;
    },
    updateGalleryProperties(state, payload) {
      // Will be called during gallery synchronization. Function sets conveniently several
      // gallery properties in one step.
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.remoteGallery.galleryId);

      gallery.title = payload.remoteGallery.title;
      gallery.numberOfSubscribers = payload.remoteGallery.numberOfSubscribers;
      gallery.writePermissionForSubscribers = payload.remoteGallery.writePermissionForSubscribers;
      gallery.updatedAt = payload.remoteGallery.updatedAt;
    },
    updateNumberOfSubscribers(state, apiResult) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === apiResult.galleryId);
      gallery.numberOfSubscribers = apiResult.numberOfSubscribers;
      gallery.updatedAt = apiResult.updatedAt;
    },
    removeGallery(state, payload) {
      state.galleries = state.galleries.filter(gallery => gallery.galleryId !== payload.galleryId);
    },
    markGalleryAsUpdated(state, payload) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.galleryId);

      gallery.updatedAt = payload.updatedAt;
    },
    markGalleryAsDeleted(state, payload) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.galleryId);

      gallery.status = CONSTANTS.STATUS.DELETED;
      gallery.syncAction = CONSTANTS.SYNC_ACTION.DELETE;
    },
    markGalleryAsUnsubscribed(state, payload) {
      const gallery = state.galleries.find(gallery => gallery.galleryId === payload.galleryId);

      gallery.status = CONSTANTS.STATUS.DELETED;
      gallery.syncAction = CONSTANTS.SYNC_ACTION.UNSUBSCRIBE;
    }
  },
  actions: {
    resetGalleries(context) {
      context.commit('resetGalleries');
    },
    setState(context, payload) {
      context.commit('setState', payload.state);
    },
    addGallery(context, payload) {
      context.commit('addGallery', payload.gallery);
    },
    createGallery(context, payload) {
      const gallery = setGalleryDefaultProperties(payload);

      context.commit('addGallery', gallery);
    },
    setActiveGalleryId(context, payload) {
      context.commit('setActiveGalleryId', payload.galleryId);
    },
    updateGalleryAfterUpload(context, payload) {
      context.commit('updateGalleryAfterUpload', payload);
    },
    finishGalleryImport(context, payload) {
      context.commit('finishGalleryImport', payload);
    },
    updateGalleryProperties(context, payload) {
      context.commit('updateGalleryProperties', payload);
    },
    removeGallery(context, payload) {
      context.commit('removeGallery', payload);
    },
    async updateGallerySettings(context, payload) {
      let apiResult = await updateGallerySettings(payload);
      context.commit('updateGallerySettings', apiResult);
    },
    async revokeGallerySubscriptions(context, payload) {
        const apiResult = await revokeGallerySubscriptions(payload.galleryId);
        context.commit('updateNumberOfSubscribers', apiResult);
    },
    markGalleryAsUpdated(context, payload) {
      context.commit('markGalleryAsUpdated', payload);
    },
    markGalleryAsDeleted(context, payload) {
      context.commit('markGalleryAsDeleted', payload);
    },
    markGalleryAsUnsubscribed(context, payload) {
      context.commit('markGalleryAsUnsubscribed', payload);
    }
  }
};
