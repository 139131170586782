import { setPersistedState } from '../../services/storageService';
import debounce from 'lodash/debounce';

async function persistState (mutation, state) {
    await setPersistedState(state);
}

export default function offlineStoragePlugin (store) {
    // using debounce for preventing document update conflicts in PouchDB
    store.subscribe(debounce(persistState, 500));
}
