const ICON_SPRITE_PATH = process.env.BASE_URL + 'img/icons-sprite.svg#';

export default {
    props: ['name'],

    computed: {
        iconLink () {
            return ICON_SPRITE_PATH + this.name;
        }
    }
};
