import Vue from 'vue';

const modalEvents = new Vue();

const MODAL_SHOW_EVENT = 'show-modal';

function createPromise(){
  const promise = {
    promise: null,
    resolve: null
  };

  promise.promise = new Promise((resolve, reject) => {
    promise.resolve = resolve;
  });

  return promise;
}

function showModal(content) {
  const payload = {
    content: content,
    promise: createPromise()
  };

  modalEvents.$emit(MODAL_SHOW_EVENT, payload);

  return payload.promise.promise;
}

export {
  showModal,
  modalEvents,
  MODAL_SHOW_EVENT
}
