import { mapGetters } from 'vuex';

export default {
    props: ['activeImageId', 'showCtrl'],
    computed: {
        ...mapGetters([
            'activeGallery',
            'imagesOfActiveGallery'
        ])
    },
    methods: {
        onItemClick (itemIndex) {
            this.$emit('set-active-image', itemIndex);
        }
    }
};
