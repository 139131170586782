import { mapGetters } from 'vuex';
import { router, routes } from '../../router';
import { subscribe, subscribeAnonymous } from '../../services/subscriptionService';

export default {
  props: ['gallery-owner-name', 'gallery-access-key'], // passed in by router

  data: () => {
    return {
      form: {
        ownerName: '',
        accessKey: ''
      },
      showError: false,
      routes: routes,
      pending: false
    };
  },

  computed: {
    ...mapGetters([
      'hasActiveSession'
    ])
  },

  methods: {
    async subscribeToGallery() {
      const credentials = {
        ownerName: this.form.ownerName,
        accessKey: this.form.accessKey
      };
      this.showError = false;
      this.pending = true;

      try {
        if (this.hasActiveSession) {
          await subscribe(credentials);
        } else {
          await subscribeAnonymous(credentials);
        }
        if (router.currentRoute.fullPath !== routes.albumEdit) {
          router.push(routes.albumEdit);
        }
      } catch (err) {
        this.showError = true;
      }
      this.pending = false;
    },

    onSubmit() {
      this.subscribeToGallery();
    }
  },

  mounted() {
    if (this.galleryAccessKey) {
      this.form.accessKey = this.galleryAccessKey;
    }
    if (this.galleryOwnerName) {
      this.form.ownerName = this.galleryOwnerName;
    }
    if (this.galleryAccessKey && this.galleryOwnerName) {
      this.subscribeToGallery();
    }
  }
};
