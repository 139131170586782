const VIEWPORTS = {
  PHONE: 'phone',
  TABLET_PORTRAIT: 'tablet-portrait',
  TABLET_LANDSCAPE: 'tablet-landscape',
  DESKTOP: 'desktop',
  BIG_DESKTOP: 'big-desktop'
};

const VIEWPORT_SIZES = {
  PHONE: 400,
  TABLET_PORTRAIT: 600,
  TABLET_LANDSCAPE: 900,
  DESKTOP: 1200,
  BIG_DESKTOP: 1800
};


function getViewport () {
  const viewportWidth = document.documentElement.clientWidth;
  let viewportTitle = VIEWPORTS.PHONE;

  viewportTitle = viewportWidth >= VIEWPORT_SIZES.TABLET_PORTRAIT ? VIEWPORTS.TABLET_PORTRAIT : viewportTitle;
  viewportTitle = viewportWidth >= VIEWPORT_SIZES.TABLET_LANDSCAPE ? VIEWPORTS.TABLET_LANDSCAPE : viewportTitle;
  viewportTitle = viewportWidth >= VIEWPORT_SIZES.DESKTOP ? VIEWPORTS.DESKTOP : viewportTitle;
  viewportTitle = viewportWidth >= VIEWPORT_SIZES.BIG_DESKTOP ? VIEWPORTS.BIG_DESKTOP : viewportTitle;

  return viewportTitle;
}

export {
  VIEWPORTS,
  getViewport
}
