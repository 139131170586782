function getImageAsDataUrl (importObject) {
    return new Promise( (resolve, reject) => {
        const
            fileReader = new FileReader();

        fileReader.onload = function () {
            if (/image/.test(importObject.file.type)) {
                importObject.originalData = fileReader.result;
                resolve(importObject);
            } else {
                reject(new Error('file is not of type image'));
            }
        };

        fileReader.readAsDataURL(importObject.file);
    });
}


export { getImageAsDataUrl };