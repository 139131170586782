import Vue from 'vue';
import VueRouter from 'vue-router';
import start from '../views/start/start.vue';
import createGallery from '../views/createGallery/createGallery.vue';
import editGallery from '../views/editGallery/editGallery.vue';
import selectGallery from '../views/selectGallery/selectGallery.vue';
import shareGallery from '../views/shareGallery/shareGallery.vue';
import slideshow from '../views/slideshow/slideshow.vue';
import settings from '../views/settings/settings.vue';
import slideshowSettings from '../views/settingsSlideshow/slideshowSettings.vue';
import login from '../views/login/login.vue';
import signin from '../views/signin/signin.vue';
import subscribe from '../views/subscribeToGallery/subscribeToGallery.vue';

Vue.use(VueRouter);

const routes = {};

routes.start = '/start';
routes.signin = `${routes.start}/signin`;
routes.login = `${routes.start}/login`;
routes.albums = `${routes.start}/albums`;
routes.albumCreate = `${routes.albums}/create`;
routes.albumEdit = `${routes.albums}/edit`;
routes.subscribe = `${routes.albums}/subscribe`;
routes.albumShare = `${routes.albumEdit}/share`;
routes.albumSlideshow = `${routes.albumEdit}/slideshow`;
routes.settings = 'settings';
routes.settingsSlideshow = 'slideshow';
// Aliases
routes.signinFromAlbumShare = `${routes.albumShare}/signin`;
routes.loginFromAlbumShare = `${routes.albumShare}/login`;

const router = new VueRouter({
  // mode: 'history',
  routes: [
    { path: '/', redirect: routes.start },
    { path: routes.start, component: start },
    { path: routes.albums, component: selectGallery },
    { path: routes.albumCreate, component: createGallery },
    { path: routes.albumEdit, component: editGallery },
    { path: routes.albumShare, component: shareGallery },
    { path: routes.albumSlideshow, component: slideshow,
      props: (route) => ({ startImageIndex: route.query.start }) },
    {
      path: '/*/' + routes.settings, component: settings, children: [
        { path: routes.settingsSlideshow, component: slideshowSettings }
      ]
    },
    { path: routes.login, component: login, alias: routes.loginFromAlbumShare },
    { path: routes.signin, component: signin, alias: routes.signinFromAlbumShare },
    { path: routes.subscribe, component: subscribe,
      props: (route) => (
        {
          galleryOwnerName: route.query.n,
          galleryAccessKey: route.query.k
        }
        )
    },
  ]
});

export {
  router,
  routes
};
