import store from '../store';

function userLogin (loginObject) {
    return store.dispatch({
        type: 'login',
        token: loginObject.token,
        username: loginObject.username
    });
}

function anonymousLogin (sessionToken) {
    store.dispatch({
        type: 'login',
        token: sessionToken,
        username: ''
    });
}

function userLogout () {
    store.dispatch({
        type: 'logout'
    });
}

export {
    userLogin,
    userLogout,
    anonymousLogin
};
