import { deleteImageFromDb } from './storageService';
import store from '../store';
import { from, merge } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { deleteRemoteImage } from '../api/galleries';

async function removeImageFromStore(image) {
  await store.dispatch({
    type: 'removeImage',
    imageId: image.imageId
  });

  return image;
}

/**
 * Marking will prevent images from showing up on templates
 * and will be used by the sync service for deleting image from server.
 * When image is successfully removed from server, it will be finally removed from store.
 * @param image
 * @returns {Promise<*>}
 */
async function markImageAsDeleted(image) {
  await store.dispatch({
    type: 'markImageAsDeleted',
    imageId: image.imageId
  });

  return image;
}

async function markGalleryAsUpdated(image) {
  await store.dispatch({
    type: 'markGalleryAsUpdated',
    galleryId: image.galleryId,
    updatedAt: image.updatedAt
  });
}

async function deleteImageFromServer(image) {
  if (image.uploaded) {
    // API call
    return await deleteRemoteImage(image);
  }
}

/**
 * Delete image from server and remove it from store.
 * @param images
 * @returns {Promise<unknown>}
 */
function deleteRemoteImages(images) {
  return from(images)
    .pipe(
      flatMap(deleteImageFromServer),
      flatMap(removeImageFromStore),
      flatMap(markGalleryAsUpdated)
    )
    .toPromise();
}

/**
 * Delete image from client database and remove it from store.
 * @param images
 * @returns {Promise<unknown>}
 */
function deleteLocalImages(images) {
  return from(images)
    .pipe(
      flatMap(removeImageFromStore),
      flatMap(deleteImageFromDb)
    )
    .toPromise();
}

/**
 * Distinguish between uploaded and not uploaded images.
 * Uploaded images are deleted from local database and marked for deletion from server. Deletion from server is
 * handled by the sync service.
 * Not uploaded images are deleted from local database and immediately removed from store.
 * @param images
 * @returns {Promise<unknown>}
 */
function deleteImages(images) {
  const uploadedImages = images.filter(image => image.uploaded);
  const notUploadedImages = images.filter(image => !image.uploaded);

  const deleteUploadedImages$ = from(uploadedImages)
    .pipe(
      flatMap(markImageAsDeleted),
      flatMap(deleteImageFromDb)
    );

  const deleteNotUploadedImages$ = from(notUploadedImages)
    .pipe(
      flatMap(deleteImageFromDb),
      flatMap(removeImageFromStore)
    );

  return merge(
    deleteNotUploadedImages$,
    deleteUploadedImages$
  ).toPromise();
}

/**
 * Function is called by sync service.
 * @returns {Promise<void>}
 */
async function removeDeletedImagesFromServer() {
  const deletedImages = store.getters.deletedImages;
  if (deletedImages.length) {
    await deleteRemoteImages(deletedImages);
  }
}

export {
  deleteImages,
  deleteLocalImages,
  deleteRemoteImages,
  removeDeletedImagesFromServer,
  removeImageFromStore
};
