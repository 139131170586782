// Require global styles. Style configurations like variables or mixins will be included
// by Webpack´s the sass resource loader.
import './assets/styles/global.scss';
import 'animate.css/animate.css';

// Require all SVG icons for webpack (is there a better way to do it?)
require.context('./assets/icons', false, /.*\.svg$/);

import './registerServiceWorker';

import Vue from 'vue';
import { router } from './router';
import i18n from './localization/i18n';
import store from './store';
import { mapGetters } from 'vuex';
import Vuelidate from 'vuelidate'
import { getPersistedState } from './services/storageService';
import merge from 'lodash/merge';

import { initApi } from './api/apiConfig';

import app from './app/app.vue';
import vueImage from './components/image/image.vue';
import vueIcon from './components/icon/icon.vue';
import vueTopCtrl from './components/topCtrl/topCtrl.vue';
import zoomImage from './components/zoomImage/zoomImage.vue';
import spinner from './components/spinner/spinner.vue';
import asyncButton from './components/asyncButton/asyncButton.vue';
import asyncToggleButton from './components/asyncToggleButton/asyncToggleButton.vue';

import focus from './directives/focus.js';

Vue.component('vue-image', vueImage);
Vue.component('vue-icon', vueIcon);
Vue.component('vue-top-ctrl', vueTopCtrl);
Vue.component('vue-zoom-image', zoomImage);
Vue.component('vue-spinner', spinner);
Vue.component('vue-async-button', asyncButton);
Vue.component('vue-async-toggle-button', asyncToggleButton);

Vue.directive('focus', focus);

Vue.use(Vuelidate);

new Vue({
  router,
  i18n,
  store,

  computed: {
    ...mapGetters([
      'currentRoute'
    ])
  },

  methods: {
    goToPersistedRoute() {
      if (this.currentRoute && this.currentRoute !== this.$route.path) {
        this.$router.push(this.currentRoute);
      }
    }
  },

  async created() {
    initApi();
    const persistedState = await getPersistedState();
    if (persistedState) {
      store.replaceState(merge({}, store.state, JSON.parse(persistedState)));
      // forward user to the last visited route
      this.goToPersistedRoute();
    }
  },

  render: h => h(app)
}).$mount('#app');
