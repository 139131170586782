import vueTopMenu from './topMenu/topMenu.vue';
import { goBack, getReturnPath } from '../../services/navigationService';
import { routes } from '../../router/index';
import { enableWakeLock } from '../../services/wakeLockService';

export default {
    components: {
        vueTopMenu
    },

    props: ['show-ctrl'],

    data: function () {
        return {
            showTopMenu: false
        };
    },

    methods: {
        onBackButtonClick() {
            const returnPath = getReturnPath();
            if (returnPath === routes.albumSlideshow) {
                // allow wake lock only on slideshow view
                enableWakeLock();
            }
            goBack();
        },

        onBurgerButtonClick() {
            this.showTopMenu = true;
        },

        closeTopMenu() {
            this.showTopMenu = false;
        }
    }
};
