import { mapGetters } from 'vuex';
import { routes } from '../../router';
import vueGalleryPreview from '../../components/galleryPreview/galleryPreview.vue';
import vueSelectGalleryCtrl from '../../components/selectGalleryCtrl/selectGalleryCtrl.vue';
import { sync } from '../../services/syncService';
import { hasUpdate, triggerUpdate } from '../../services/appUdatedService';

const PREVIEW_UPDATE_INTERVAL = 7000;

export default {

  data: () => {
    return {
      updateTicker: 0,
      showCtrl: false
    };
  },

  components: {
    vueGalleryPreview,
    vueSelectGalleryCtrl
  },

  computed: {
    ...mapGetters([
      'galleries',
      'activeGallery'
    ])
  },

  methods: {
    onGalleryClick(galleryId) {
      this.$store.dispatch({
        type: 'setActiveGalleryId',
        galleryId: galleryId
      });
      this.$router.push(routes.albumEdit);
    },
    showCtrlWithDelay() {
      setTimeout(() => {
        this.showCtrl = true;
      }, 500);
    }
  },

  mounted() {
    this.showCtrlWithDelay();
    setInterval(() => {
      this.updateTicker++;
    }, PREVIEW_UPDATE_INTERVAL);
  }
};
