import { getImage } from '../../services/storageService';
import { CONSTANTS } from '../../shared/constants';
import vueImagePlaceholder from '../imagePlaceholder/imagePlaceholder.vue';

const IMAGE_BASE_URL = 'https://sharedphotoframe.s3.eu-central-1.amazonaws.com/';
const IMAGE_SUFFIX = '.jpg';
const IMAGE_THUMBNAIL_SUFFIX = '_thumb.jpg';

function getRemotImageUrl(imageId, imageType) {
  const suffix = (imageType === 'thumbnailImage') ? IMAGE_THUMBNAIL_SUFFIX : IMAGE_SUFFIX;
  return IMAGE_BASE_URL + imageId + suffix;
}

export default {
  components: {
    vueImagePlaceholder
  },

  props: ['imageId', 'type', 'status', 'uploaded'],

  data: function () {
    return {
      imageSrc: ''
    };
  },

  computed: {
    isLoading() {
      return this.status === CONSTANTS.STATUS.LOADING;
    }
  },

  methods: {
    async loadImage(imageId, imageType) {
      if (this.uploaded) {
        this.imageSrc = getRemotImageUrl(imageId, imageType);
      } else {
        const imageBlob = await getImage(imageId, imageType);
        this.imageSrc = window.URL.createObjectURL(imageBlob);
      }
    },

    onLoad(event) {
      // release memory
      window.URL.revokeObjectURL(this.imageSrc);
      // Pass loaded image to parent component for positioning.
      this.$emit('image-loaded', event.currentTarget);
    },

    onPlaceholderLoaded(element) {
      this.$emit('image-loaded', element);
    }
  },

  mounted() {
    if (this.imageId && this.status !== CONSTANTS.STATUS.LOADING) {
      this.loadImage(this.imageId, this.type);
    }
  },

  watch: {
    imageId: function (newImageId) {
      this.loadImage(newImageId, this.type);
    },
    status: function (newStatus, oldStatus) {
      if (newStatus !== CONSTANTS.STATUS.LOADING && oldStatus === CONSTANTS.STATUS.LOADING) {
        this.loadImage(this.imageId, this.type);
      }
    }

  }
};
