import { mapGetters } from 'vuex';
import vueTransitionPreview from '../../components/transitionPreview/transitionPreview.vue';

const slideshow = {
    types: [
        {
            name: 'ken-burns',
            interval: [
                { label: 'slow', value: 12000 },
                { label: 'normal', value: 7000 },
                { label: 'fast', value: 4000 },
            ]
        },
        {
            name: 'slide',
            interval: [
                { label: 'slow', value: 12000 },
                { label: 'normal', value: 7000 },
                { label: 'fast', value: 4000 },
            ]
        },
        {
            name: 'cross-fade',
            interval: [
                { label: 'slow', value: 12000 },
                { label: 'normal', value: 7000 },
                { label: 'fast', value: 4000 },
            ]
        }
    ]
};

export default {

    components: {
        vueTransitionPreview
    },

    data: () => {
        return {
            model: {
                transitionOptions: [
                    { text: 'Ken-Burns', value: 'ken-burns' },
                    { text: 'Cross-Fade', value: 'cross-fade' },
                    { text: 'Slide', value: 'slide' }
                ],
                durationOptions: [6000, 70000, 8000],
                durationUnitOptions: [
                    { text: 'Sekunden', value: 1 },
                    { text: 'Minuten', value: 60 },
                    { text: 'Stunden', value: 3600 }
                ]
            }
        };
    },

    computed: {
        ...mapGetters([
            'activeSlideshow',
            'slideshowIntervalDuration'
        ]),

        slideshowTransitionName: {
            get () {
                return this.$store.state.settings.slideshow.activeType;
            },
            set (value) {
                this.$store.commit('setActiveSlideshow', value);
            }
        },

        slideshowIntervalDuration: {
            get () {
                return this.activeSlideshow.interval;
            },
            set (value) {
                this.$store.commit('setSlideshowIntervalDuration', value);
            }
        },


        intervalOptions() {
            const selectedSlideshowSettings = slideshow.types.find( type => type.name === this.activeSlideshow.name);

            return selectedSlideshowSettings.interval;
        }
    }
};
