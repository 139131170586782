import { canvasToBlob } from 'blob-util';

const SETTINGS = {
  THUMBNAIL: {
    WIDTH: 240,
    HEIGHT: 240,
    QUALITY: 0.9
  },
  MAIN_IMAGE: {
    WIDTH: 2048,
    HEIGHT: 1536,
    QUALITY: 0.9
  },
  IMAGE_FILE_TYPE: 'image/jpeg'
};

function loadImage(importObject) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => {
      importObject.image = image;
      resolve(importObject);
    };
    image.onerror = (err) => reject(err);
    // load image
    image.src = importObject.originalData || importObject.url;
  });
}

function createThumbnail(importObject) {
  const
    canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    sourceX = 0,
    sourceY = 0,
    sourceWidth = importObject.image.width, // clipping width
    sourceHeight = importObject.image.height, // clipping height
    destinationX = 0,
    destinationY = 0;

  let scaleRatio,
    destinationWidth,
    destinationHeight;

  scaleRatio = SETTINGS.THUMBNAIL.WIDTH / importObject.image.width;
  destinationWidth = SETTINGS.THUMBNAIL.WIDTH;
  destinationHeight = Math.floor(importObject.image.height * scaleRatio);


  // set canvas to destination size
  canvas.width = destinationWidth;
  canvas.height = destinationHeight;

  ctx.drawImage(
    importObject.image,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    destinationX,
    destinationY,
    destinationWidth,
    destinationHeight
  );

  return new Promise((resolve) => {
    canvasToBlob(canvas, SETTINGS.IMAGE_FILE_TYPE, SETTINGS.THUMBNAIL.QUALITY)
      .then((blob) => {
        importObject.thumbnailImage = blob;
        resolve(importObject);
      });
  });
}

function createMainImage(importObject) {
  const
    canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    sourceX = 0,
    sourceY = 0,
    sourceWidth = importObject.image.width, // clipping width
    sourceHeight = importObject.image.height, // clipping height
    destinationX = 0,
    destinationY = 0;

  let scaleRatio,
    destinationWidth,
    destinationHeight;

  // if landscape
  if (importObject.image.width > importObject.image.height) {
    scaleRatio = SETTINGS.MAIN_IMAGE.WIDTH / importObject.image.width;
    destinationWidth = SETTINGS.MAIN_IMAGE.WIDTH;
    destinationHeight = Math.floor(importObject.image.height * scaleRatio);
  } else {
    scaleRatio = SETTINGS.MAIN_IMAGE.HEIGHT / importObject.image.height;
    destinationWidth = Math.floor(importObject.image.width * scaleRatio);
    destinationHeight = SETTINGS.MAIN_IMAGE.HEIGHT;
  }

  // set canvas to destination size
  canvas.width = destinationWidth;
  canvas.height = destinationHeight;

  ctx.drawImage(
    importObject.image,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    destinationX,
    destinationY,
    destinationWidth,
    destinationHeight
  );

  return new Promise((resolve) => {
    canvasToBlob(canvas, SETTINGS.IMAGE_FILE_TYPE, SETTINGS.MAIN_IMAGE.QUALITY)
      .then((blob) => {
        importObject.mainImage = blob;
        importObject.ratio = scaleRatio;
        resolve(importObject);
      });
  });
}

export { loadImage, createThumbnail, createMainImage };
