import { mapGetters } from 'vuex';
import { importLocaleImages } from '../../../services/localImageImportService';
import { sync } from '../../../services/syncService';
import { LIMITS } from '../../../services/limitsService';
import { showImageLimitReachedNotification } from '../../../services/notificationService';

export default {
  computed: {
    ...mapGetters([
      'activeGallery',
      'imagesOfActiveGallery'
    ])
  },
  methods: {
    async importSelectedFiles(fileList) {
      try {
        await importLocaleImages(fileList, this.activeGallery.galleryId);
        sync();
      } catch (err) {
        console.log('local image import failed or cancelled!', );
      }
    },

    async onInputChange(e) {
      const totalNumberOfImages = this.imagesOfActiveGallery.length + e.target.files.length;
      if (totalNumberOfImages <= LIMITS.MAX_GALLERY_IMAGES) {
        this.importSelectedFiles(e.target.files)
      } else {
        showImageLimitReachedNotification();
      }
    }
  }

};
