import { routes } from '../../router'
import { hasUpdate, triggerUpdate } from '../../services/appUdatedService';
import { sync } from '../../services/syncService';

export default {
  data() {
    return {
      routes,
      demoGalleryOwnerName: 'Comillo',
      demoGalleryAccessKey: '777597'
    }
  },

  created() {
    // Navigating to this view will automatically trigger an app update if one is available.
    if (hasUpdate()) {
      triggerUpdate();
    } else {
      sync();
    }
  },

};
