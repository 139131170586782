import { mapGetters } from 'vuex';
import store from '../../store'
import vueSlideshowNavigation from '../../components/slideshowNavigation/slideshowNavigation.vue';
import vueSlideshowCtrl from '../../components/slideshowCtrl/slideshowCtrl.vue';
import vueSplashIcon from '../../components/splashIcon/splashIcon.vue';
import { getAnimationClass } from '../../services/slideshowService';
import { EVENT_NAMES, eventBus } from '../../services/eventService';

const DEFAULT_TRANSITION = 'cross-fade-fast';
const SWIPE_THRESHOLD = 70;

let dragStartX = 0;
let dragX = 0;

export default {
  components: {
    vueSlideshowNavigation,
    vueSlideshowCtrl,
    vueSplashIcon
  },

  props: ['startImageIndex'], // passed in by router

  data() {
    return {
      activeImageIndex: 0,
      slideshowInterval: null,
      isSlideshowPlaying: false,
      showCtrl: true,
      animationClass: '', // Additional animation class on images for special animation (eg. Ken Burns)
      transitionName: '',
      transitionDuration: 3000,
      splashIconName: '',
      style: {}
    };
  },

  computed: {
    ...mapGetters([
      'activeGallery',
      'imagesOfGallery',
      'getImageById',
      'activeSlideshow',
      'slideshowTransitionName',
      'slideshowTransitionDuration',
    ]),
    images() {
      return this.imagesOfGallery(this.activeGallery.galleryId);
    },
    activeImageId() {
      if (this.activeGallery && this.images.length) {
        return this.images[this.activeImageIndex].imageId;
      }
    },
    activeImage() {
      return this.getImageById(this.activeImageId);
    }
  },

  methods: {
    beforeEnter() {
      // Certain slide show transition effects need additional classes on the slide show images.
      // These classes can be different for each slide show image (eg. Ken Burns effect).
      this.animationClass = getAnimationClass(this.transitionName);
    },
    nextImage() {
      this.activeImageIndex = (this.activeImageIndex + 1) % this.images.length;
    },
    prevImage() {
      this.activeImageIndex = (((this.activeImageIndex - 1) % this.images.length)
        + this.images.length) % this.images.length;
    },
    startSlideshow() {
      // Use transition name from slideshow settings store.
      this.transitionName = this.activeSlideshow.name;
      this.isSlideshowPlaying = true;
      this.slideshowInterval = setInterval(this.nextImage, this.activeSlideshow.interval);
    },
    stopSlideshow() {
      this.isSlideshowPlaying = false;
      clearInterval(this.slideshowInterval);
    },
    restartSlideshow() {
      this.stopSlideshow();
      this.startSlideshow();
    },
    showSplashIcon(name) {
      this.splashIconName = name;
      setTimeout(() => {
        this.splashIconName = '';
      }, 1);
    },
    onSetActiveImage(itemIndex) {
      this.activeImageIndex = parseInt(itemIndex);
      if (this.isSlideshowPlaying) {
        this.restartSlideshow();
      }
    },
    onSlideClick() {
      this.showCtrl = !this.showCtrl;
      if (this.isSlideshowPlaying) {
        this.onPauseSlideshow();
      }
    },
    onNextImage() {
      this.nextImage();
      if (this.isSlideshowPlaying) {
        this.restartSlideshow();
      }
    },
    onPrevImage() {
      this.prevImage();
      if (this.isSlideshowPlaying) {
        this.restartSlideshow();
      }
    },
    onPauseSlideshow() {
      this.showSplashIcon('pause');
      this.stopSlideshow();
      // Switch to default transition when using/showing slideshow controls.
      this.transitionName = DEFAULT_TRANSITION;
      this.animationClass = getAnimationClass(this.transitionName);
    },
    onPlaySlideshow() {
      this.showSplashIcon('play');
      this.nextImage();
      this.startSlideshow();
      this.showCtrl = false;
    },
    onGoBack() {
      this.showCtrl = false;
      // Delay back navigation for letting control animation finish.
      setTimeout(() => {
        history.back();
      }, 300);
    },
    hideZoomImage() {
      setTimeout(() => {
        eventBus.$emit(EVENT_NAMES.ZOOM_IMAGE_HIDE);
      }, 500);
    },
    setHeader() {
      if (this.showCtrl) {
        eventBus.$emit(EVENT_NAMES.HEADER_SHOW);
      } else {
        eventBus.$emit(EVENT_NAMES.HEADER_HIDE);
      }
    },
    onTouchStart(event) {
      dragStartX = event.touches[0].clientX;
    },
    onTouchEnd() {
      if (dragX > SWIPE_THRESHOLD) {
        this.onPrevImage();
        dragX = 0;
      }
      if (dragX < -SWIPE_THRESHOLD) {
        this.onNextImage();
        dragX = 0;
      }
    },
    onTouchMove(event) {
      dragX = event.touches[0].clientX - dragStartX;
    }
  },

  mounted() {
    this.setHeader();
    this.hideZoomImage();
    if (this.startImageIndex) {
      this.onSetActiveImage(this.startImageIndex);
    } else {
      this.showCtrl = false;
      this.startSlideshow();
    }
  },

  watch: {
    // Start slideshow when active gallery is received from store.
    activeGallery() {
      if (!this.isSlideshowPlaying) {
        this.startSlideshow();
      }
    },
    showCtrl() {
      this.setHeader();
    },
    activeImage() {
      // if image is new
      if (this.activeImage.isNew) {
        // mark image as seen (unmark new image)
        store.dispatch({
          type: 'unmarkImageAsNew',
          imageId: this.activeImage.imageId
        });
      }
    }
  },

  beforeDestroy: function () {
    clearInterval(this.slideshowInterval);
  }
};
