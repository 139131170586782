/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { enableUpdate } from './services/appUdatedService';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(test) {
      console.log('Service worker has been registered.', test);
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(serviceWorkerRegistration) {
      console.log('New content is available; please refresh.', serviceWorkerRegistration);
      enableUpdate(serviceWorkerRegistration);
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });

  let reloaded = false;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange',
      function () {
        if (!reloaded) {
          reloaded = true;
          // Reload client when service worker has changed.
          window.location.reload();
        }
      }
    );
  }

}
