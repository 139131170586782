import axios from 'axios';
import { CONST } from './apiConfig';

const apiEndpoint = 'login';

function login (credentials) {
    const apiUrl = [CONST.API_BASE_URL, apiEndpoint].join('/');
    return axios.post(apiUrl, credentials);
}

export default login;
