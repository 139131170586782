import vueFileSelection from './fileSelection/fileSelection.vue';
import { mapGetters } from 'vuex';
import { routes } from '../../router';
import { deleteGallery } from '../../services/galleryDeletionService';
import { cancelRemoteImageImport } from '../../services/remoteImageImportService';
import { cancelLocalImageImport } from '../../services/localImageImportService';
import { showDeleteGalleryNotification, showUnsubscribeNotification } from '../../services/notificationService';
import { enableWakeLock } from '../../services/wakeLockService';

export default {
  components: {
    vueFileSelection
  },

  props: ['number-of-subscribers'],

  data: () => {
    return {
      importingImages: false
    };
  },

  computed: {
    ...mapGetters([
      'activeGallery',
      'isAuthenticated',
      'isGalleryLoadingImages'
    ]),
    selectedImages() {
      return this.activeGallery ? this.activeGallery.images.filter((image) => image.selected) : [];
    },
    isGalleryOwned() {
      return this.activeGallery.isOwned;
    },
    isGalleryEditable() {
      return this.activeGallery.isOwned || this.activeGallery.writePermissionForSubscribers
    },
    loadingImages() {
      return this.isGalleryLoadingImages(this.activeGallery.galleryId);
    }
  },

  methods: {
    onStartImageDeletionButtonClick() {
      this.$emit('start-image-deletion');
    },

    onSharingButtonClick() {
      this.$router.push(routes.albumShare);
    },

    async onDeleteGalleryButtonClick() {
      const result = await showDeleteGalleryNotification();
      if (result.resolved) {
        await deleteGallery(this.activeGallery);
        this.$router.push(routes.albums);
      }
    },

    onPlayButtonClick() {
      enableWakeLock();
      this.$router.push(routes.albumSlideshow);
    },

    onCancelImageImportButtonClick() {
      cancelLocalImageImport();
      cancelRemoteImageImport();
    },

    async onUnsubscribeButtonClick() {
      const result = await showUnsubscribeNotification();
      if (result.resolved) {
        await deleteGallery(this.activeGallery);
        this.$router.push(routes.albums);
      }
    }

  }

};
