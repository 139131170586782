import vueEditGalleryCtrl from '../../components/editGalleryCtrl/editGalleryCtrl.vue';
import vueDeleteImagesCtrl from '../../components/deleteImagesCtrl/deleteImagesCtrl.vue';
import vueImagePlaceholder from '../../components/imagePlaceholder/imagePlaceholder.vue';
import { mapGetters } from 'vuex';
import {routes} from '../../router';
import { deleteImages } from '../../services/imageDeletionService';
import { EVENT_NAMES, eventBus } from '../../services/eventService';
import { sync } from '../../services/syncService';
import { enableWakeLock } from '../../services/wakeLockService';

function hasUserEditPermission(gallery) {
    return gallery.isOwned || gallery.writePermissionForSubscribers;
}

export default {
    data: () => {
        return {
            showCtrl: false,
            showDeleteImagesCtrl: false,
            zoomImageId: null,
            zoomImageStyles: {},
            hideScrollbar: false,
            imageDeletionMode: false
        };
    },

    components: {
        vueEditGalleryCtrl,
        vueDeleteImagesCtrl,
        vueImagePlaceholder
    },

    computed: {
        ...mapGetters([
            'activeGallery',
            'imagesOfActiveGallery'
        ]),
        selectedImages() {
            return this.imagesOfActiveGallery.filter((image) => image.selected);
        },
        images() {
            return this.imagesOfActiveGallery;
        },
        isGalleryEditable() {
            return this.activeGallery.isOwned || this.activeGallery.writePermissionForSubscribers
        }
    },

    methods: {
        onItemClick(image, event, imageIndex) {
            if (this.imageDeletionMode) {
                image.selected = !image.selected;
            } else {
                // start slideshow
                eventBus.$emit(EVENT_NAMES.ZOOM_IMAGE_SHOW, {
                    imageId: image.imageId,
                    uploaded: image.uploaded,
                    imageIndex: imageIndex,
                    offsetTop: event.target.offsetTop + 50 + 'px',
                    offsetLeft: event.target.offsetLeft + 'px',
                    offsetHeight: event.target.offsetHeight + 'px',
                    offsetWidth: event.target.offsetWidth + 'px',
                });
                enableWakeLock();
                setTimeout(() => {
                    this.$router.push({ path: routes.albumSlideshow, query: { start: imageIndex} });
                }, 100);
            }
        },

        showCtrlWithDelay() {
            setTimeout(() => {
                this.showCtrl = true;
            }, 500);
        },

        startImageDeletion() {
            this.imageDeletionMode = true;
            this.showCtrl = false;
            this.showDeleteImagesCtrl = true;
        },

        async stopImageDeletion() {
            this.imageDeletionMode = false;
            this.showCtrl = true;
            this.showDeleteImagesCtrl = false;
            // await deleteLocaleAndRemoteImages(this.selectedImages);
            await deleteImages(this.selectedImages);
            sync();
        },

        cancelImageDeletion() {
            this.imageDeletionMode = false;
            this.selectedImages.forEach((image) => {
                image.selected = false;
            });
            this.showCtrl = true;
            this.showDeleteImagesCtrl = false;
        },

        resizeGridItem(element) {
            const gridElement = this.$refs.grid.$el;
            const gridRowGap = parseInt(window.getComputedStyle(gridElement).getPropertyValue('grid-row-gap'));
            const gridRowHeight = parseInt(window.getComputedStyle(gridElement).getPropertyValue('grid-auto-rows'));
            const gridItemElement = element.parentElement;
            // Item height is the height of the thumbnail image or
            // the width of the grid element (makes it a square placeholder).
            const itemHeight = element.height || gridItemElement.offsetWidth * 0.6;
            const rowSpan = Math.ceil((itemHeight + gridRowGap) / (gridRowHeight + gridRowGap));
            gridItemElement.style.gridRowEnd = 'span ' + rowSpan;
        },

        onImageLoaded(element) {
            this.resizeGridItem(element);
        }

    },

    watch: {
        activeGallery() {
            if (this.activeGallery && hasUserEditPermission(this.activeGallery)) {
                this.showCtrlWithDelay();
            }
        }
    },

    mounted() {
        // Make sure header is shown.
        eventBus.$emit(EVENT_NAMES.HEADER_SHOW);
        if (this.activeGallery && hasUserEditPermission(this.activeGallery)) {
            this.showCtrlWithDelay();
        }
        this.showCtrlWithDelay();
    }

};

