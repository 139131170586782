import { router } from '../router/index.js'

function getReturnPath() {
  const pathArray = router.currentRoute.path.split('/');
  let returnPath;

  if (pathArray.length > 2) {
    // remove last path segment
    pathArray.pop();
    returnPath = pathArray.join('/');
  } else {
    console.log('no back navigation (root page)');
  }

  return returnPath;
}

function goBack() {
  const returnPath = getReturnPath(router.currentRoute);
  if (returnPath) {
    router.push(returnPath);
  }
}

export {
  getReturnPath,
  goBack
}
