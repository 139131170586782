import { createUUID } from '../../services/uuidService';
import { clearLocalDB } from '../../services/storageService';

function getDefaultState() {
    return {
        userId: createUUID(),
        token: '',
        username: ''
    };
}

export default {
    state: getDefaultState(),
    getters: {
        userId(state) {
            return state.userId;
        },
        token(state) {
            return state.token;
        },
        username(state) {
            return state.username;
        },
        isAuthenticated(state) {
            return (state.token && state.username);
        },
        hasActiveSession(state) {
            return state.token;
        },
        isAnonymousUser(state) {
            return (state.token && !state.username);
        }
    },
    mutations: {
        resetUser(state) {
            Object.assign(state, getDefaultState());
        },
        setUserId(state, userId) {
            state.userId = userId;
        },
        setToken(state, token) {
            state.token = token;
        },
        setUsername(state, username) {
            state.username = username || '';
        }
    },
    actions: {
        setUserId(context, payload) {
            context.commit('setUserId', payload.userId);
        },
        setToken(context, payload) {
            context.commit('setToken', payload.token);
        },
        removeToken(context) {
            context.commit('setToken', '');
        },
        login(context, payload) {
            context.commit('setToken', payload.token);
            context.commit('setUsername', payload.username);
        },
        logout(context) {
            context.commit('resetUser');
            context.commit('resetSettings');
            context.commit('resetGalleries');
            context.commit('resetImages');
            clearLocalDB();
        }
    }
};
