import { CONSTANTS } from '../../shared/constants';
import {deleteImageFromDb} from '../../services/storageService';

function getDefaultState() {
  return {
    images: []
  };
}

/**
 * id:          ID only for list renderings (as value for the 'key' attribute in v-for lists)
 *              duplicates in case of broken uploads.
 * imageId:     ID by which an image is referenced locally and remotely. After an image upload this ID will be
 *              updated to a new one provided by the backend.
 * galleryId:   ID of the gallery the image belongs to
 * name:        File name of the image
 * isNew:       Marks an image as newly added by a remote client
 * uploaded:    Marks an image as uploaded to the server
 * selected:    Marks an image as selected by the user through the edit view
 * status:      Status information (LOADING, DELETED, READY)
 * syncAction:  Property stores a selected action when offline.
 *              The action can then be triggered later when online again.
 */
function setImageDefaultProperties(payload) {
  return {
    id: payload.importObject.imageId,
    imageId: payload.importObject.imageId,
    galleryId: payload.importObject.galleryId,
    name: payload.importObject.name,
    isNew: payload.importObject.isNew || false,
    uploaded: payload.importObject.uploaded,
    selected: false,
    status: CONSTANTS.STATUS.LOADING,
    syncAction: CONSTANTS.SYNC_ACTION.NONE
  };
}

export default {
  state: getDefaultState(),
  getters: {
    getImageById(state) {
      return (id) => state.images.find(image => image.imageId === id);
    },
    imagesOfGallery(state) {
      return (galleryId) => state.images.filter(image => image.galleryId === galleryId);
    },
    imagesOfActiveGallery(state, getters, rootState, rootGetters) {
      return state.images
        .filter(image => image.galleryId === rootGetters.activeGallery.galleryId)
        .filter(image => image.status !== CONSTANTS.STATUS.DELETED);
    },
    deletedImages(state) {
      return state.images.filter(image => image.syncAction === CONSTANTS.SYNC_ACTION.DELETE);
    },
    loadingImages(state) {
      return state.images.filter(image => image.status === CONSTANTS.STATUS.LOADING);
    },
    uploadedImagesOfGallery(state) {
      return (id) => {
        return state.images
          .filter(image => image.galleryId === id)
          .filter(image => image.uploaded);
      };
    },
    notUploadedImages(state) {
      return state.images
        .filter(image => !image.uploaded);
    },
    notUploadedImagesOfGallery(state) {
      return (galleryId) => {
        return state.images
          .filter(image => image.galleryId === galleryId)
          .filter(image => !image.uploaded)
      };
    },
    notUploadedImagesOfSubscribedGalleries(state, getters, rootState, rootGetters) {
      let images = [];

      rootGetters.subscribedGalleries.forEach( gallery => {
        images = [...images, ...state.images
          .filter(image => image.galleryId === gallery.galleryId)]
          .filter(image => image.syncAction === CONSTANTS.SYNC_ACTION.ADD);
      });

      return images;
    },
    isGalleryLoadingImages(state) {
      return (galleryId) => {
          const loadingImages = state.images
          .filter(image => image.galleryId === galleryId)
          .filter(image => image.status === CONSTANTS.STATUS.LOADING);

          return loadingImages.length > 0;
      };
    }

  },
  mutations: {
    resetImages(state) {
      Object.assign(state, getDefaultState());
    },
    addImage(state, image) {
      state.images.unshift(image);
    },
    removeImage(state, payload) {
      state.images = state.images.filter(image => image.imageId !== payload.imageId);
    },
    markImageAsDeleted(state, payload) {
      const image = state.images.find(image => image.imageId === payload.imageId);
      image.status = CONSTANTS.STATUS.DELETED;
      image.syncAction = CONSTANTS.SYNC_ACTION.DELETE;
    },
    updateImageAfterUpload(state, payload) {
      const image = state.images.find(image => image.imageId === payload.clientImageId);

      if (image) {
        // Change local image ID to the new ID provided by the server.
        image.imageId = payload.serverImageId;
        image.uploaded = true;
        image.status = CONSTANTS.STATUS.READY;
        image.syncAction = CONSTANTS.SYNC_ACTION.NONE
      } else {
        console.log('Error updating image after upload', payload);
      }
    },
    updateImagesAfterGalleryUpload(state, payload) {
      const imagesOfGallery = state.images.filter(image => image.galleryId === payload.oldGalleryId);

      imagesOfGallery.forEach(image => {
        image.galleryId = payload.newGalleryId;
      });
    },
    finishLocalImageImport(state, payload) {
      const image = state.images.find(image => image.imageId === payload.importObject.imageId);

      if (payload.importObject.status === CONSTANTS.STATUS.ERROR) {
        state.images = state.images.filter(image => image.imageId !== payload.importObject.imageId);
      } else {
        image.status = CONSTANTS.STATUS.READY;
        image.syncAction = CONSTANTS.SYNC_ACTION.ADD;
      }
    },
    finishRemoteImageImport(state, payload) {
      const image = state.images.find(image => image.imageId === payload.importObject.imageId);

      image.status = CONSTANTS.STATUS.READY;
      image.syncAction = CONSTANTS.SYNC_ACTION.NONE;
    },
    unmarkImageAsNew(state, payload) {
      const image = state.images.find(image => image.imageId === payload.imageId);

      image.isNew = false;
    },
    cleanupAfterCancelledImageImport(state, payload) {
      // Delete images of gallery which are of status LOADING.
      state.images = state.images.filter(
        image => !(image.galleryId === payload.galleryId
          && image.status === CONSTANTS.STATUS.LOADING)
      );
    }
  },
  actions: {
    resetImages(context) {
      context.commit('resetImages');
    },
    addImage(context, payload) {
      const image = setImageDefaultProperties(payload);
      context.commit('addImage', image);
    },
    removeImage(context, payload) {
      context.commit('removeImage', payload);
    },
    markImageAsDeleted(context, payload) {
      context.commit('markImageAsDeleted', payload);
    },
    async updateImageAfterUpload(context, payload) {
      try {
        await context.commit('updateImageAfterUpload', payload);
        // remove image from local DB
        await deleteImageFromDb(payload);
      } catch (error) {
        console.log('error updateImageAfterUpload', error);
      }
    },
    async fixImageAfterBrokenUpload(context, payload) {
      try {
        context.commit('updateImageAfterUpload', payload);
      } catch (error) {
        console.log('error fixImageAfterBrokenUpload', error);
      }
    },
    updateImagesAfterGalleryUpload(context, payload) {
      context.commit('updateImagesAfterGalleryUpload', payload);
    },
    finishLocalImageImport(context, payload) {
      context.commit('finishLocalImageImport', payload);
    },
    finishRemoteImageImport(context, payload) {
      context.commit('finishRemoteImageImport', payload);
    },
    unmarkImageAsNew(context, payload) {
      context.commit('unmarkImageAsNew', payload);
    },
    cleanupAfterCancelledImageImport(context, payload) {
      context.commit('cleanupAfterCancelledImageImport', payload);
    }
  }
};
