import axios from 'axios';
import store from '../store';

// Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Do something with response data
//     return response;
// }, function (error) {
//     // Perform a client side user logout whenever server returns status 401.
//     if (error.response && error.response.status === 401) {
//         console.log('user logout');
//         userLogout();
//     }
//     return Promise.reject(error);
// });

const CONST = {
    API_BASE_URL: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3000/api'
};

const API_STATUS_CODES = {
    SUBSCRIPTION_NOT_FOUND: 'SUBSCRIPTION_NOT_FOUND',
    GALLERY_NOT_FOUND: 'GALLERY_NOT_FOUND'
};

function setAuthHeader (sessionToken) {
    axios.defaults.headers.common['Authorization'] = sessionToken;
}

/**
 * Set authorization header property whenever user token in store changes.
 */
function initApi () {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.timeout = 10000;
    store.watch(
        () => store.getters.token,
        (token) => {
            setAuthHeader(token);
        }
    );
}

export {
    CONST,
    API_STATUS_CODES,
    setAuthHeader,
    initApi
};
