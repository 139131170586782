export default {
    HOME_MENU_CREATE_TITLE: 'Create',
    HOME_MENU_CREATE_SUBTITLE: '',
    HOME_MENU_SUBSCRIBE_TITLE: 'Import',
    HOME_MENU_SUBSCRIBE_SUBTITLE: '',
    HOME_MENU_SHOW_TITLE: 'Show',
    HOME_MENU_SHOW_SUBTITLE: '',

    TITLE_SHARING: 'Sharing',
    TITLE_PHOTOFRAME: 'Photo frame',


    BTN_CONTINUE: 'Continue',
    BTN_ADD_PHOTOS: 'Add photos',
    BTN_SLIDESHOW: 'Slideshow',
    BTN_SHARE: 'Share',
    BTN_UPDATE: 'Update',
    BTN_DELETE_SELECTION: 'Delete selection',
    BTN_DELETE_PHOTOFRAME: 'Delete photo frame',

    SHARING_DESCRIPTION: ''
}