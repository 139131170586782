import createGalleryForm from '../../components/createGalleryForm/createGalleryForm.vue';
import { LIMITS } from '../../services/limitsService';
import { showGalleriesLimitReachedNotification } from '../../services/notificationService';
import { routes } from '../../router';
import { mapGetters } from 'vuex';

export default {
  components: {
    createGalleryForm
  },

  computed: {
    ...mapGetters([
      'ownedGalleries'
    ])
  },

  methods: {
    async checkGalleriesLimit() {
      if (this.ownedGalleries.length >= LIMITS.MAX_GALLERIES) {
        try {
          await showGalleriesLimitReachedNotification();
          this.$router.push(routes.albums);
        } catch (err) {
          this.$router.push(routes.albums);
        }
      }
    }
  },

  mounted() {
    this.checkGalleriesLimit();
  }
};
