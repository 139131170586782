import { routes } from '../../router';
import apiLogin from '../../api/login';
import { userLogin } from '../../services/userService';
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  data: () => {
    return {
      form: {
        email: '',
        password: ''
      },
      showLoginError: false,
      showValidationErrors: false,
      pending: false,
      routes: routes
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },

  methods: {
    async onSubmitLogin() {
      if (this.$v.$invalid) {
        this.showValidationErrors = true;
      } else {
        this.showValidationErrors = false;
        try {
          this.pending = true;
          const apiResult = await apiLogin(this.form);
          await userLogin(apiResult.data);
          this.pending = false;
          this.$router.push(routes.albums);
        } catch (err) {
          console.log('Login error: ', err);
          this.showLoginError = true;
          this.pending = false;
        }
      }
    }
  }
};
