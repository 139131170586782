import vueSettingsMenu from '../../components/settingsMenu/settingsMenu.vue';
import { mapGetters } from 'vuex';
import { routes } from '../../router';

export default {
    components: {
        vueSettingsMenu
    },

    computed: {
        ...mapGetters([
            'currentRoute'
        ]),

        isSettingsChildRoute(){
            const lastRouteSegment = this.currentRoute.split('/').pop();
            return lastRouteSegment !== routes.settings;
        }
    }

};
