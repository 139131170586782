import store from '../store';
import { mapGetters } from 'vuex';
import { EVENT_NAMES, eventBus } from '../services/eventService';
import vueModal from '../components/modal/modal.vue'
import { sync } from '../services/syncService';
import { goBack } from '../services/navigationService';
import { disableWakeLock } from '../services/wakeLockService';
import { routes } from '../router/index';


export default {
  components: {
    vueModal
  },
  data() {
    return {
      showHeader: true,
      noScrolling: false
    };
  },

  computed: {
    ...mapGetters([
      'currentRoute',
      'token'
    ])
  },


  methods: {
    onGoBack() {
     goBack();
    },

    scrollToTop() {
      this.$el.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  },

  watch: {
    $route: function (to) {
      store.dispatch({
        type: 'setCurrentRoute',
        currentRoute: to.path
      });
      if (to.path !== routes.albumSlideshow) {
        disableWakeLock();
      }
    },
    token: function (token) {
      // Trigger sync whenever a user token is set (user has logged in).
      if (token) {
        sync();
      }
    }
  },

  mounted() {
    console.log('app mounted');
    sync(); // initial synchronization

    eventBus.$on(EVENT_NAMES.HEADER_SHOW, () => {
      this.showHeader = true;
    });
    eventBus.$on(EVENT_NAMES.HEADER_HIDE, () => {
      this.showHeader = false;
    });
    eventBus.$on(EVENT_NAMES.DISABLE_SCROLLING, () => {
      this.noScrolling = true;
    });
    eventBus.$on(EVENT_NAMES.ENABLE_SCROLLING, () => {
      this.noScrolling = false;
    });
    eventBus.$on(EVENT_NAMES.SCROLL_TOP, () => {
      this.scrollToTop();
    });
  }

};
