export default {

    methods: {
        onStopDeletionButtonClick () {
            this.$emit('stop-image-deletion');
        },

        onCancelDeletionButtonClick () {
            this.$emit('cancel-image-deletion');
        }
    }
};