import { importRemoteImages } from './remoteImageImportService';
import store from '../store';
import { Subject } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { CONSTANTS } from '../shared/constants';
import { router, routes } from '../router';

function addGalleryToStore(remoteGallery) {
  return store.dispatch({
    type: 'createGallery',
    galleryId: remoteGallery.galleryId,
    title: remoteGallery.title,
    isOwned: remoteGallery.isOwned,
    ownerName: remoteGallery.ownerName,
    writePermissionForSubscribers: remoteGallery.writePermissionForSubscribers,
    numberOfSubscribers: remoteGallery.numberOfSubscribers,
    uploaded: true,
    syncAction: CONSTANTS.SYNC_ACTION.NONE
  });
}

function finishGalleryImport(galleryId, updatedAt) {
  return store.dispatch({
    type: 'finishGalleryImport',
    galleryId: galleryId,
    updatedAt: updatedAt
  });
}

function filterAlreadyImportedGalleries(remoteGalleries) {
  return remoteGalleries.filter(remoteGallery => {
    return !store.getters.getGalleryById(remoteGallery.galleryId);
  });
}

async function importGallery(remoteGallery) {
  await addGalleryToStore(remoteGallery);
  if (router.currentRoute.fullPath !== routes.albumEdit) {
    router.push(routes.albumEdit);
  }
  await importRemoteImages(remoteGallery.images, remoteGallery.galleryId, false);
  await finishGalleryImport(remoteGallery.galleryId, remoteGallery.updatedAt);
}

function importGalleries(remoteGalleries) {
  const filteredGalleries = filterAlreadyImportedGalleries(remoteGalleries);
  const importSubject$ = new Subject();
  let importIndex = 0;
  let resolveImportPromise;
  let rejectImportPromise;

  importSubject$
    .pipe(
      flatMap(importGallery),
      catchError((err, source$) => {
        // Abort import of galleries without throwing an error.
        importSubject$.complete();
        return source$;
      })
    )
    .subscribe(
      () => {
        importIndex++;
        if (importIndex < filteredGalleries.length) {
          // wait a second to give loaded image time to be visible to the user
          setTimeout(() => {
            importSubject$.next(filteredGalleries[importIndex]);
          }, 1000);
        } else {
          importSubject$.complete();
        }
      },
      (err) => {
        console.log('error importGalleries', err);
      },
      () => {
        resolveImportPromise();
      });

  if (filteredGalleries[importIndex]) {
    importSubject$.next(filteredGalleries[importIndex]);
  } else {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    resolveImportPromise = resolve;
    rejectImportPromise = reject;
  });

}

export {
  importGallery,
  importGalleries,
  addGalleryToStore
};

