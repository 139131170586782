import { mapGetters } from 'vuex';
import { router, routes } from '../../../router';
import { userLogout } from '../../../services/userService';


export default {
  props: ['show-top-menu'],

  computed: {
    ...mapGetters([
      'username',
      'isAuthenticated'
    ]),
    appVersion() {
      return process.env.VUE_APP_VERSION;
    }
  },

  methods: {
    onCloseMenuButtonClick() {
      this.$emit('close-top-menu');
    },

    onSettingsItemClick() {
      this.$emit('close-top-menu');
      this.$router.push(this.$router.currentRoute.path + '/' + routes.settings);
    },

    onLogoutItemClick() {
      // todo: send request to API for invalidation of token
      userLogout();
      this.$emit('close-top-menu');
      if (router.currentRoute.fullPath !== routes.start) {
        this.$router.push(routes.start);
      }
    },

    onLoginItemClick() {
      this.$emit('close-top-menu');
      this.$router.push(routes.login);
    },

    onSigninItemClick() {
      this.$emit('close-top-menu');
      this.$router.push(routes.signin);
    }
  }
};
