import { EVENT_NAMES, eventBus } from '../../services/eventService';
import velocity from 'velocity-animate';

export default {
    data() {
        return {
            zoomImage: {}
        };
    },

    methods: {
        beforeEnter(el) {
            el.style.width = this.zoomImage.offsetWidth;
            el.style.top = this.zoomImage.offsetTop;
            el.style.left = this.zoomImage.offsetLeft;
        },
        enter(el, done) {
            velocity(el,
                {
                    width: ['100%', this.zoomImage.offsetWidth],
                    height: ['100%', this.zoomImage.offsetHeight],
                    left: 0,
                    top: 0
                },
                { duration: 500, easing: [0.3, .9, 0.7, 1], complete: done }
            );
        },
        afterEnter() {
        },
        leave(el, done) {
            velocity(el,
                { opacity: 0 },
                { duration: 300, easing: 'ease-out', complete: done }
            );
        }
    },

    mounted() {
        eventBus.$on(EVENT_NAMES.ZOOM_IMAGE_SHOW, (payload) => {
            this.zoomImage = payload;
        });
        eventBus.$on(EVENT_NAMES.ZOOM_IMAGE_HIDE, () => {
            this.zoomImage = {};
        });
    }
};
