import Vue from 'vue';

const eventBus = new Vue();

const EVENT_NAMES = {
    ZOOM_IMAGE_SHOW: 'show-zoom-image',
    ZOOM_IMAGE_HIDE: 'hide-zoom-image',
    HEADER_SHOW: 'show-header',
    HEADER_HIDE: 'hide-header',
    SCROLL_TOP: 'scroll-top',
    DISABLE_SCROLLING: 'disable-scrolling',
    ENABLE_SCROLLING: 'enable-scrolling',
};

export {
    eventBus,
    EVENT_NAMES
};
