import Vue from "vue";
import VueI18n from "vue-i18n";
import localizationEN from "./en";
import localizationDE from "./de";

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'de',
    messages: {
        en: localizationEN,
        de: localizationDE
    }
});