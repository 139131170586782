import store from '../store';
import { Subject, from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { getImage } from './storageService';
import { createGallery, uploadImageToGallery } from '../api/galleries';

function createUploadObjects(images) {
  return images.map((image) => {
    return {
      galleryId: image.galleryId,
      imageId: image.imageId,
      imageFile: null,
      imageThumbnailFile: null,
      imageName: image.name
    };
  });
}

async function attachImageFiles(uploadObject) {
  uploadObject.imageFile = await getImage(uploadObject.imageId, 'mainImage');
  uploadObject.imageThumbnailFile = await getImage(uploadObject.imageId, 'thumbnailImage');

  return uploadObject;
}

async function updatesAfterUpload(apiResult) {
  // update local image ID
  await store.dispatch({
    type: 'updateImageAfterUpload',
    serverImageId: apiResult.serverImageId,
    clientImageId: apiResult.clientImageId
  });

  await store.dispatch({
    type: 'markGalleryAsUpdated',
    galleryId: apiResult.galleryId,
    updatedAt: apiResult.updatedAt
  });
}

function addNewImagesOnServer() {
  let newImages = [];
  const uploadSubject$ = new Subject();
  let uploadIndex = 0;
  let resolveUploadPromise;
  let rejectUploadPromise;

  if (store.getters.isAuthenticated) {
    newImages = store.getters.notUploadedImages
  } else if (store.getters.isAnonymousUser) {
    newImages = store.getters.notUploadedImagesOfSubscribedGalleries;
  }

  const imageUploadObjects = createUploadObjects(newImages);

  uploadSubject$
    .pipe(
      flatMap(attachImageFiles),
      flatMap(uploadImageToGallery),
      flatMap(updatesAfterUpload)
    ).subscribe(
      (i) => {
        uploadIndex++;
        if (uploadIndex < imageUploadObjects.length) {
          uploadSubject$.next(imageUploadObjects[uploadIndex]);
        } else {
          uploadSubject$.complete();
        }
      },
      (err) => {
        rejectUploadPromise(err);
      },
      () => {
        resolveUploadPromise();
      }
    );

  if (imageUploadObjects[uploadIndex]) {
      uploadSubject$.next(imageUploadObjects[uploadIndex]);
  } else {
      return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    resolveUploadPromise = resolve;
    rejectUploadPromise = reject;
  });
}

async function uploadGallery(gallery) {
  try {
    const apiResult = await createGallery({
      galleryId: gallery.galleryId,
      title: gallery.title
    });
    await store.dispatch({
      type: 'updateGalleryAfterUpload',
      oldGalleryId: apiResult.galleryId,
      newGalleryId: apiResult._id,
      updatedAt: apiResult.updatedAt
    });
    await store.dispatch({
      type: 'updateImagesAfterGalleryUpload',
      oldGalleryId: apiResult.galleryId,
      newGalleryId: apiResult._id
    });
  } catch (error) {
    console.log('error', error);
  }
}

/**
 * Uploads all local (not yet uploaded) galleries and images to the backend
 * @returns {Promise<any>}
 */
function addNewGalleriesOnServer() {
  // Upload new local galleries only if user is authenticated.
  let localGalleries = store.getters.isAuthenticated ? store.getters.notUploadedGalleries : [];
  let uploadGalleries$ = from(localGalleries);

  return uploadGalleries$
    .pipe(flatMap(uploadGallery))
    .toPromise();
}

async function enableUploadOfLocalGalleries() {
  return store.dispatch({
    type: 'enableUploadOfLocalGalleries'
  });
}

export {
  addNewImagesOnServer,
  uploadGallery,
  addNewGalleriesOnServer,
  enableUploadOfLocalGalleries
};
