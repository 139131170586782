export default {
    props: ['show-ctrl', 'is-slideshow-playing'],

    methods: {
        onPauseButtonClick () {
            this.$emit('pause-slideshow');
        },
        onPlayButtonClick () {
            this.$emit('play-slideshow');
        },
        onPrevButtonClick () {
            this.$emit('prev-image');
        },
        onNextButtonClick () {
            this.$emit('next-image');
        }
    }
};