import { EVENT_NAMES, eventBus } from '../../services/eventService';
import {modalEvents, MODAL_SHOW_EVENT} from '../../services/modalService';

export default {

  data() {
    return {
      modalData: null,
      isVisible: false,
      isInnerVisible: false
    }
  },

  methods: {
    onDeclineButtonClick() {
      this.modalData.promise.resolve({ resolved: false });
      this.hideModal();
    },

    onConfirmButtonClick() {
      this.modalData.promise.resolve({ resolved: true });
      this.hideModal();
    },

    showInner() {
      // timeout for animation
      setTimeout( () => {
        this.isInnerVisible = true;
      }, 300);
    },

    showModal(data) {
      this.modalData = data;
      this.isVisible = true;
      this.showInner();
      eventBus.$emit(EVENT_NAMES.DISABLE_SCROLLING);
    },

    hideModal() {
      this.modalData = null;
      this.isVisible = false;
      this.isInnerVisible = false;
      eventBus.$emit(EVENT_NAMES.ENABLE_SCROLLING);
    }
  },

  mounted() {
    modalEvents.$on(MODAL_SHOW_EVENT, (payload)=> {
      this.showModal(payload);
    });
  }
}
