import store from '../store';
import { deleteLocalImages} from './imageDeletionService';
import { deleteRemoteGallery } from '../api/galleries';
import { from } from 'rxjs';
import { flatMap } from 'rxjs/operators';


async function markGalleryAsDeleted(gallery) {
    return store.dispatch({
        type: 'markGalleryAsDeleted',
        galleryId: gallery.galleryId
    });
}

async function markGalleryAsUnsubscribed(gallery) {
    return store.dispatch({
        type: 'markGalleryAsUnsubscribed',
        galleryId: gallery.galleryId
    });
}

async function removeGalleryFromStore(galleryId) {
    await store.dispatch({
        type: 'removeGallery',
        galleryId: galleryId
    });
}

function deleteGalleryFromServer(gallery) {
    // API call
    return deleteRemoteGallery(gallery.galleryId);
}

function deleteRemoteGalleries(galleries) {
    return from(galleries)
      .pipe(
        flatMap(deleteGalleryFromServer),
        flatMap(removeGalleryFromStore)
      )
      .toPromise();
}

/**
 * Function is called by sync service.
 * @returns {Promise<void>}
 */
async function removeDeletedGalleriesFromServer() {
    const deletedGalleries = store.getters.deletedGalleries;
    if (deletedGalleries.length) {
        await deleteRemoteGalleries(deletedGalleries);
    }
}


/**
 * Deletion of local gallery!
 * @param gallery
 * @returns {Promise<void>}
 */
async function deleteLocalGallery(gallery) {
    const galleryImages = store.getters.imagesOfGallery(gallery.galleryId);
    try {
        await deleteLocalImages(galleryImages);
        await removeGalleryFromStore(gallery.galleryId);
    } catch (err) {
        console.log('error: deleteLocalGallery', err);
    }
}

/**
 * Complete deletion (remote and local) of a gallery.
 * @returns {Promise<void>}
 */
async function deleteGallery(gallery) {
    try {
        if (gallery.uploaded) {
            const galleryImages = store.getters.imagesOfGallery(gallery.galleryId);
            await deleteLocalImages(galleryImages);
            if (gallery.isOwned) {
                await markGalleryAsDeleted(gallery);
            } else {
                await markGalleryAsUnsubscribed(gallery);
            }
        } else {
            await deleteLocalGallery(gallery);
        }
    } catch (err) {
        console.log('error: deleteGallery', err);
    }
}



export {
    deleteLocalGallery,
    deleteGallery,
    deleteRemoteGallery,
    removeDeletedGalleriesFromServer
};
