const ORIGIN_CLASSES = [
    'origin-left-top',
    'origin-left-center',
    'origin-left-bottom',
    'origin-center-top',
    'origin-center-center',
    'origin-center-bottom',
    'origin-right-top',
    'origin-right-center',
    'origin-right-bottom'
];

const ZOOM_CLASSES = [
    'zoom-in',
    'zoom-out'
];


/**
 * Function will return a string with two css class names. The class names will be choosen randomly to provide
 * the certain Ken Burns animation effect.
 * @returns {string}
 */
function getKenBurnsClasses () {
    return 'ken-burns'
        + ' '
        + ZOOM_CLASSES[Math.floor(Math.random() * ZOOM_CLASSES.length)]
        + ' ' + ORIGIN_CLASSES[Math.floor(Math.random() * ORIGIN_CLASSES.length)];
}

function getAnimationClass (animationName) {
    let animationClass = '';

    switch (animationName) {
        case 'ken-burns':
            animationClass = getKenBurnsClasses();
            break;
    }

    return animationClass;
}

export {
    getAnimationClass
};
