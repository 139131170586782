import { mapGetters } from 'vuex';
import { routes } from '../../router';
import { getGalleryAccessKey } from '../../services/gallerySharingService';
import vueAsyncToggleButton from '../../components/asyncToggleButton/asyncToggleButton.vue';
import vueAsyncButton from '../../components/asyncButton/asyncButton.vue';
import { showStopSharingGalleryNotification } from '../../services/notificationService';

function buildGalleryAccessLink(galleryAccess) {
  const baseUrl = process.env.BASE_URL.slice(0, -1);
  return `${window.location.origin}${baseUrl}${routes.subscribe}?n=${galleryAccess.userName}&k=${galleryAccess.key}`;
}

export default {
  components: {
    vueAsyncToggleButton,
    vueAsyncButton
  },
  data: () => {
    return {
      galleryAccess: {},
      galleryAccessLink: '',
      routes,
      isWritePermissionPending: false,
      showWritePermissionError: false,
      isSharingLinkPending: false,
      showSharingLinkError: false,
      isStopSharingPending: false,
      showStopSharingError: false
    };
  },
  computed: {
    ...mapGetters([
      'activeGallery',
      'isAuthenticated',
      'username'
    ])
  },
  methods: {
    async onGetAccessKeyButtonClick() {

      if (!this.isSharingLinkPending) {
        this.showSharingLinkError = false;
        this.isSharingLinkPending = true;
        try {
          this.galleryAccess = await getGalleryAccessKey(this.activeGallery.galleryId);
          this.galleryAccessLink = buildGalleryAccessLink(this.galleryAccess);
        } catch (err) {
          this.showSharingLinkError = true;
        }
        this.isSharingLinkPending = false;
      }
    },

    onCopyButtonClick() {
      this.$refs.accesskey.select();
      document.execCommand('copy');
    },

    async stopSharing() {
      if (!this.isStopSharingPending) {
        this.isStopSharingPending = true;
        this.showStopSharingError = false;
        try {
          await this.$store.dispatch({
            type: 'revokeGallerySubscriptions',
            galleryId: this.activeGallery.galleryId,
          });
        } catch (err) {
          this.showStopSharingError = true;
        }
        this.isStopSharingPending = false;
      }
    },

    async onStopSharingButtonClick() {
      const result = await showStopSharingGalleryNotification();
      if (result.resolved) {
        await this.stopSharing();
      }
    },

    async onWritePermissionButtonClick() {
      if (!this.isWritePermissionPending) {
        this.isWritePermissionPending = true;
        this.showWritePermissionError = false;
        try {
          await this.$store.dispatch({
            type: 'updateGallerySettings',
            galleryId: this.activeGallery.galleryId,
            settingsKey: 'writePermissionForSubscribers',
            settingsValue: !this.activeGallery.writePermissionForSubscribers
          });
        } catch (err) {
          this.showWritePermissionError = true;
        }
        this.isWritePermissionPending = false;
      }
    },


    onToggleButtonClick() {
      this.pending = true;
      setTimeout(() => {
        this.test = !this.test;
        this.pending = false;
      }, 2000)
    }
  }


};
