export default {
  props: ['value', 'pending'],

  computed: {
    label() {
      let label = '';
      if (this.value === true) {
        label = 'On';
      } else if (this.value === false) {
        label = 'Off'
      }

      return label;
    }
  }
}
