import { createMainImage, createThumbnail, loadImage } from './imageService';
import { saveImageToDb } from './storageService';
import store from '../store';
import { from, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../shared/constants';
import { EVENT_NAMES, eventBus } from './eventService';

const cancelRemoteImageImport$ = new Subject();
let importing = false;

function addImageToStore(importObject) {
  store.dispatch({
    type: 'addImage',
    importObject: importObject
  });

  return importObject;
}

function finishRemoteImageImport(importObject) {
  store.dispatch({
    type: 'finishRemoteImageImport',
    importObject: importObject
  });

  return importObject;
}

function cleanupUnfinishedImport(galleryId) {
  store.dispatch({
    type: 'cleanupAfterCancelledImageImport',
    galleryId: galleryId
  });
}

function createRemoteImageImportObjects(images, galleryId, isNewImage) {
  return images.map((image) => {
    return {
      galleryId: galleryId,
      imageId: image.imageId,
      url: image.url,
      uploaded: true,
      isNew: isNewImage,
      syncAction: CONSTANTS.SYNC_ACTION.NONE
    };
  });
}

function importRemoteImages(images, galleryId, isNewImage) {
  const imageImportObjects = createRemoteImageImportObjects(images, galleryId, isNewImage);
  let importSuccessCounter = 0;
  let resolveImportPromise;
  let rejectImportPromise;
  const importPromise = new Promise((resolve, reject) => {
    resolveImportPromise = resolve;
    rejectImportPromise = reject;
  });

  if (imageImportObjects.length === 0) {
    return Promise.resolve();
  }

  importing = true;
  eventBus.$emit(EVENT_NAMES.SCROLL_TOP);

  from(imageImportObjects)
    .pipe(
      map(addImageToStore),
      // mergeMap(loadImage),
      // mergeMap(createThumbnail),
      // mergeMap(createMainImage),
      // mergeMap(saveImageToDb),
      map(finishRemoteImageImport),
      takeUntil(cancelRemoteImageImport$)
    )
    .subscribe(
      (i) => {
        importSuccessCounter++;
      },
      (err) => {
        importing = false;
        cleanupUnfinishedImport(galleryId);
        rejectImportPromise();
      },
      () => {
        if (importSuccessCounter !== imageImportObjects.length) {
          console.log('cancelled image import',);
          // Image import is not complete (canceled by user). Remove unfinished imports.
          cleanupUnfinishedImport(galleryId);
          rejectImportPromise('CANCELED');
        }
        resolveImportPromise();
        importing = false;
      }
    );

  return importPromise;
}

// SEQUENCIAL IMPORT
//
// function importRemoteImages(images, galleryId, isNewImage) {
//   const imageImportObjects = createRemoteImageImportObjects(images, galleryId, isNewImage);
//   const importSubject$ = new Subject();
//   let importIndex = 0;
//   let resolveImportPromise;
//   let rejectImportPromise;
//
//   if (imageImportObjects.length) {
//     importing = true;
//     eventBus.$emit(EVENT_NAMES.SCROLL_TOP);
//   }
//
//   importSubject$
//     .map(addImageToStore)
//     .mergeMap(loadImage)
//     .mergeMap(createThumbnail)
//     .mergeMap(createMainImage)
//     .mergeMap(saveImageToDb)
//     .map(finishRemoteImageImport)
//     .takeUntil(cancelRemoteImageImport$)
//     .subscribe(
//       (i) => {
//         importIndex++;
//         if (importIndex < imageImportObjects.length) {
//           importSubject$.next(imageImportObjects[importIndex]);
//         } else {
//           importSubject$.complete();
//         }
//       },
//       (err) => {
//         console.log('local image import: ', err);
//         importing = false;
//         cleanupUnfinishedImport(galleryId);
//         rejectImportPromise();
//       },
//       () => {
//         if (importIndex !== imageImportObjects.length) {
//           console.log('cancelled image import', );
//           // Image import is not complete (canceled by user). Remove unfinished imports.
//           cleanupUnfinishedImport(galleryId);
//           rejectImportPromise('CANCELED');
//         }
//         resolveImportPromise();
//         importing = false;
//       }
//     );
//
//   if (imageImportObjects[importIndex]) {
//     importSubject$.next(imageImportObjects[importIndex]);
//   } else {
//     return Promise.resolve();
//   }
//
//   return new Promise((resolve, reject) => {
//     resolveImportPromise = resolve;
//     rejectImportPromise = reject;
//   });
// }

function cancelRemoteImageImport() {
  if (importing) {
    console.log('cancelRemoteImageImport',);
    cancelRemoteImageImport$.next(true);
  }
}

export {
  importRemoteImages,
  cancelRemoteImageImport
};
