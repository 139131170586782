import localforage from 'localforage';
import {CONSTANTS} from '../shared/constants';

const PERSISTED_STATE_KEY = 'state';

function setPersistedState(state) {
    console.log('setPersistedState', state);
    return localforage.setItem(PERSISTED_STATE_KEY, JSON.stringify(state));
}

function getPersistedState() {
    return localforage.getItem(PERSISTED_STATE_KEY);
}

async function saveImageToDb(importObject) {
    try {
        await localforage.setItem(importObject.imageId, importObject.mainImage);
        await localforage.setItem(importObject.imageId + CONSTANTS.THUMBNAIL_SUFFIX, importObject.thumbnailImage);
    } catch (error) {
        console.log('error saveImageToDb: ', error);
        importObject.status = CONSTANTS.STATUS.ERROR;
    }

    return importObject;
}

async function deleteImageFromDb(deletionObject) {
    // todo: optimize input type
    const imageId = deletionObject.imageId || deletionObject.clientImageId;
    try {
        await localforage.removeItem(imageId);
        await localforage.removeItem(imageId + CONSTANTS.THUMBNAIL_SUFFIX);
    } catch (error) {
        console.log('deleteImageFromDb: ', error);
    }
    return deletionObject;
}

function getImage(imageId, imageType = 'thumbnailImage') {
    return imageType === 'thumbnailImage' ?
        localforage.getItem(imageId + CONSTANTS.THUMBNAIL_SUFFIX) : localforage.getItem(imageId);
}

function initLocalDB() {
    localforage.config({
        driver: [
            localforage.INDEXEDDB,
            localforage.LOCALSTORAGE
        ],
        name: 'comilloDB'
    });
}

function clearLocalDB() {
    localforage.clear();
}

initLocalDB();

export {
    saveImageToDb,
    deleteImageFromDb,
    getImage,
    getPersistedState,
    setPersistedState,
    clearLocalDB
};
