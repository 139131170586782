import { mapGetters } from 'vuex';
import { getAnimationClass } from '../../services/slideshowService';

const PREVIEW_IMAGES = [
    'transition-preview-1',
    'transition-preview-2'
];

export default {

    data: () => {
        return {
            previewImageName: PREVIEW_IMAGES[0],
            transitionInterval: null,
            animationClass: ''
        };
    },

    computed: {
        ...mapGetters([
            'slideshowTransitionName',
        ])
    },

    methods: {
        beforeEnter () {
            // Certain slide show transition effects need additional classes on the slide show images.
            // These classes can be different for each slide show image (eg. Ken Burns effect).
            this.animationClass = getAnimationClass(this.slideshowTransitionName);
        },

        nextImage () {
            this.previewImageName =
                (this.previewImageName === PREVIEW_IMAGES[0]) ? PREVIEW_IMAGES[1] : PREVIEW_IMAGES[0];
        },

        startPreview () {
            this.nextImage();
            this.transitionInterval = setInterval(this.nextImage, 6000);
        },

        reStartPreview () {
            clearInterval(this.transitionInterval);
            this.startPreview();
        }
    },

    mounted () {
        this.startPreview();
    },

    watch: {
        // Restart preview whenever transition type changes.
        slideshowTransitionName () {
            this.reStartPreview();
        }
    },

    beforeDestroy: function () {
        clearInterval(this.transitionInterval);
    }

};
