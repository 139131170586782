import Vue from 'vue';
import { mapGetters } from 'vuex';
import { getViewport, VIEWPORTS } from '../../services/viewportService';
import vueGalleryStatus from './galleryStatus/galleryStatus.vue';
import debounce from 'lodash/debounce';

const
    NUMBER_OF_PREVIEW_IMAGES_PHONE = 6,
    NUMBER_OF_PREVIEW_IMAGES_TABLET = 8,
    NUMBER_OF_PREVIEW_IMAGES_DESKTOP = 10,
    NUMBER_OF_PREVIEW_IMAGES_DESKTOP_BIG = 14,
    PREVIEW_UPDATE_DELAY = 600;

export default {
    components: {
        vueGalleryStatus
    },

    props: ['gallery-id', 'update-ticker', 'preview-index'],

    data: function () {
        return {
            gallery: null,
            previewImages: [],
            imageIndex: -1,
            numberOfPreviewImages: 8
        };
    },

    computed: {
        ...mapGetters([
            'getGalleryById',
            'imagesOfGallery'
        ]),
        images() {
            return this.imagesOfGallery(this.galleryId);
        }
    },

    methods: {
        getNextImageIndex() {
            this.imageIndex = this.imageIndex + 1;
            return (this.imageIndex) % this.images.length;
        },

        showNextImage(previewImageIndex) {
            Vue.set(this.previewImages, previewImageIndex, this.images[this.getNextImageIndex()]);
            previewImageIndex = previewImageIndex + 1;
            if (previewImageIndex < this.numberOfPreviewImages) {
                setTimeout(() => {
                    this.showNextImage(previewImageIndex);
                }, 80);
            }
        },

        showNextImages() {
            this.showNextImage(0);
        },

        getNumberOfPreviewImages() {
            const viewport = getViewport();

            switch(viewport) {
                case(VIEWPORTS.PHONE):
                    return NUMBER_OF_PREVIEW_IMAGES_PHONE;
                case(VIEWPORTS.TABLET_PORTRAIT):
                case(VIEWPORTS.TABLET_LANDSCAPE):
                    return NUMBER_OF_PREVIEW_IMAGES_TABLET;
                case(VIEWPORTS.DESKTOP):
                    return NUMBER_OF_PREVIEW_IMAGES_DESKTOP;
                case(VIEWPORTS.BIG_DESKTOP):
                    return NUMBER_OF_PREVIEW_IMAGES_DESKTOP_BIG;
                default:
                    return NUMBER_OF_PREVIEW_IMAGES_TABLET;
            }
        },

        updatePreviewImages() {
            setTimeout(() => {
                this.showNextImages();
            }, PREVIEW_UPDATE_DELAY * parseInt(this.previewIndex));
        },

        resetPreviewImages() {
            this.numberOfPreviewImages = this.getNumberOfPreviewImages();
            this.previewImages = [];
            this.showNextImages();
        }

    },

    watch: {
        updateTicker() {
            this.updatePreviewImages();
        }
    },

    created: function () {
        window.addEventListener('resize', debounce(this.resetPreviewImages, 500))
    },

    mounted() {
        this.gallery = this.getGalleryById(this.galleryId);
        this.numberOfPreviewImages = this.getNumberOfPreviewImages();
        setTimeout(() => {
            this.updatePreviewImages();
        }, 100);
    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.resetPreviewImages)
    }

};
