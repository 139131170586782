import axios from 'axios';
import { CONST } from './apiConfig';

const apiEndpoint = 'galleries';

async function createGallery(galleryProps) {
  const apiUrl = [CONST.API_BASE_URL, 'galleries'].join('/');

  try {
    let apiResult = await axios.post(apiUrl, galleryProps);
    return apiResult.data;
  } catch (error) {
    console.log('error createGallery: ', error);
  }
}

async function updateGallerySettings(updateObject) {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    updateObject.galleryId,
    'settings'
  ].join('/');

  let apiResult = await axios.put(apiUrl, updateObject);
  return apiResult.data;
}

async function uploadImageToGallery(uploadObject) {
  let formData = new FormData();

  formData.append('imageId', uploadObject.imageId);
  formData.append('imageName', uploadObject.imageName);
  formData.append('imageFile', uploadObject.imageFile);
  formData.append('imageThumbnailFile', uploadObject.imageThumbnailFile);

  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    uploadObject.galleryId,
    'images'
  ].join('/');

  let apiResult = await axios.post(apiUrl, formData);
  return uploadObject.result = apiResult.data;
}

async function deleteRemoteImage(deletionObject) {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    deletionObject.galleryId,
    'images',
    deletionObject.imageId
  ].join('/');
  try {
    let apiResult = await axios.delete(apiUrl);
    deletionObject.updatedAt = apiResult.data.updatedAt;
    return deletionObject;
  } catch (error) {
    console.log('error deleteRemoteImage', error);
  }
}

async function getGalleryAccessKeyFromApi(galleryId) {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    galleryId,
    'accessKey'
  ].join('/');

  let apiResult;

  try {
    apiResult = await axios.get(apiUrl);
  } catch (err) {
    console.log('Error getGalleryAccessKeyFromApi: ', err);
  }

  return apiResult.data;
}

async function revokeGallerySubscriptions(galleryId) {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    galleryId,
    'revokeSubscriptions'
  ].join('/');

  let apiResult;

  try {
    apiResult = await axios.put(apiUrl);
  } catch (err) {
    console.log('Error getGalleryAccessKeyFromApi: ', err);
  }

  return apiResult.data;
}

async function deleteRemoteGallery(galleryId) {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint,
    galleryId
  ].join('/');

  try {
    await axios.delete(apiUrl);
    return galleryId;
  } catch (err) {
    console.log('Error deleteRemoteGallery: ', err);
  }
}

async function getRemoteGalleries() {
  const apiUrl = [
    CONST.API_BASE_URL,
    apiEndpoint
  ].join('/');

  let apiResult;

  try {
    apiResult = await axios.get(apiUrl);
  } catch (err) {
    console.log('Error getGallery: ', err);
  }

  return apiResult.data;
}


export {
  createGallery,
  updateGallerySettings,
  uploadImageToGallery,
  deleteRemoteImage,
  getGalleryAccessKeyFromApi,
  revokeGallerySubscriptions,
  deleteRemoteGallery,
  getRemoteGalleries
};
