import { routes } from '../../router';
import { mapGetters } from 'vuex';
import { LIMITS } from '../../services/limitsService';
import { showGalleriesLimitReachedNotification } from '../../services/notificationService';

export default {
    computed: {
        ...mapGetters([
            'ownedGalleries'
        ])
    },
    methods: {
        onCreateGalleryButtonClick(){
            if (this.ownedGalleries.length < LIMITS.MAX_GALLERIES) {
                this.$router.push(routes.albumCreate);
            } else {
                showGalleriesLimitReachedNotification();
            }
        },
        onImportGalleryButtonClick() {
            this.$router.push(routes.subscribe);
        }
    }
};
