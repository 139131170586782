export const CONSTANTS = {
    STATUS: {
        LOADING: 'LOADING',
        READY: 'READY',
        DELETED: 'DELETED',
        ERROR: 'ERROR'
    },
    SYNC_ACTION: {
        NONE: 'NONE',
        ADD: 'ADD',
        DELETE: 'DELETE',
        UNSUBSCRIBE: 'UNSUBSCRIBE'
    },
    THUMBNAIL_SUFFIX: '_thumb'
};
