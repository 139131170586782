import store from '../store';
import { from, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { getImageAsDataUrl } from './fileReaderService';
import { createMainImage, createThumbnail, loadImage } from './imageService';
import { saveImageToDb } from './storageService';
import { createUUID } from './uuidService';
import { EVENT_NAMES, eventBus } from './eventService';

const cancelLocalImageImport$ = new Subject();
let importing = false;

function addImageToStore(importObject) {
  store.dispatch({
    type: 'addImage',
    importObject: importObject
  });

  return importObject;
}

async function finishLocalImageImport(importObject) {
  await store.dispatch({
    type: 'finishLocalImageImport',
    importObject: importObject
  });

  return importObject;
}

function cleanupUnfinishedImport(galleryId) {
  store.dispatch({
    type: 'cleanupAfterCancelledImageImport',
    galleryId: galleryId
  });
}

function isImage(fileType) {
  return fileType.search('image') !== -1;
}

function createLocaleImageImportObjects(fileList, galleryId) {
  const importObjects = [];
  [...fileList].forEach((file) => {
    if (isImage(file.type)) {
      importObjects.push({
        galleryId: galleryId,
        imageId: createUUID(),
        file: file,
        name: file.name,
        uploaded: false
      });
    }
  });

  return importObjects;
}

function importLocaleImages(fileList, galleryId) {
  const imageImportObjects = createLocaleImageImportObjects(fileList, galleryId);
  const importImages$ = from(imageImportObjects);
  let importSuccessCounter = 0;
  let resolveImportPromise;
  let rejectImportPromise;
  const importPromise = new Promise((resolve, reject) => {
    resolveImportPromise = resolve;
    rejectImportPromise = reject;
  });

  if (imageImportObjects.length === 0) {
    return Promise.resolve();
  }

  importing = true;
  eventBus.$emit(EVENT_NAMES.SCROLL_TOP);

  importImages$
    .pipe(
      map(addImageToStore),
      mergeMap(getImageAsDataUrl),
      mergeMap(loadImage),
      mergeMap(createThumbnail),
      mergeMap(createMainImage),
      mergeMap(saveImageToDb),
      mergeMap(finishLocalImageImport),
      takeUntil(cancelLocalImageImport$)
    )
    .subscribe(
      (i) => {
        importSuccessCounter++;
      },
      (err) => {
        console.log('local image import: ', err);
        importing = false;
        cleanupUnfinishedImport(galleryId);
        rejectImportPromise();
      },
      () => {
        if (importSuccessCounter !== imageImportObjects.length) {
          // Image import is not complete (canceled by user). Remove unfinished imports.
          cleanupUnfinishedImport(galleryId);
          rejectImportPromise();
        }
        resolveImportPromise();
        importing = false;
      }
    );

  return importPromise;
}

function cancelLocalImageImport() {
  if (importing) {
    console.log('cancelLocalImageImport',);
    cancelLocalImageImport$.next(true);
  }
}

export {
  importLocaleImages,
  cancelLocalImageImport
};
